import gql from "graphql-tag";

export const GET_ALL_MODELS_VIDEOS = gql`
  query getAllModelVideos($page: Float!, $itemsPerPage: Float!,$isnew: Boolean,$mostViewed: Boolean) {
    getAllModelVideos(page: $page, itemsPerPage: $itemsPerPage,isnew: $isnew,mostViewed: $mostViewed) {
      media {
        id
        title
        format
        preview_url
        new
        mostViewed
        duration
        thumbnail
        tags {
          id
          name
        }
      }
      pages
      count
      currentPage
    }
  }
`;

export const GET_MODEL_TYPES_COUNT = gql`
  query getModelTypeCount {
    getModelTypeCount {
      lesbain
      gay
      straight
      trans
      bisexual
      total
      models{
        username
        profilePic
      }
    }
  }
`;

export const GET_ALL_MODELS_VIDEOS_BY_TAGS = gql`
  query getAllModelVideosByTags(
    $page: Float!
    $itemsPerPage: Float!
    $tags: String!
  ) {
    getAllModelVideosByTags(
      page: $page
      itemsPerPage: $itemsPerPage
      tags: $tags
    ) {
      media {
        id
        title
        format
        preview_url
        duration
        thumbnail
        tags {
          id
          name
        }
      }
      pages
      count
      currentPage
    }
  }
`;

export const GET_ALL_TAGS = gql`
  query getAllTags {
    getAllTags {
      tag_name
      media_count
    }
  }
`;
export const GET_ALL_SUBSCRIPTIONS = gql`
  query {
    porntoolSubscriptions {
      id
      packageId
      packageName
      subscriptionDuration
      price
    }
  }
`;

export const GET_MEDIA_INFO = gql`
  query getMediaInfo($media: Float!) {
    getMediaInfo(media: $media) {
      id
      title
      format
      url
      preview_url
      duration
      thumbnail
      tags {
        id
        name
      }
      gallery{
      owner{
        id
        username
        }
      }
    }
  }
`;

export const USER_LOGIN = gql`
  mutation login(
    $usernameOrEmail: String!
    $password: String!
    $addr: String!
  ) {
    login(usernameOrEmail: $usernameOrEmail, password: $password, addr: $addr) {
      errors {
        field
        message
      }
      token
      user {
        authSource
        profilePic
        username
        email
        profileSetupStep
        profileComplete
        photos {
          id
          image
          unsafe
        }
        base_profile {
          id
          firstName
          lastName
        }
        user_basic {
          location
        }
      }
      credit {
        id
        balance
        flirtons
        flirtonsAvailable
      }
    }
  }
`;

export const CHECKOUT_STRIPE = gql`
  mutation createStripeCheckout($input: CheckoutInput!) {
    createStripeCheckout(input: $input)
  }
`;

export const GET_USER_PORNTOOL_SUBSCRIPTIONS = gql`
  query getUserPorntoolSubscriptions {
    getUserPorntoolSubscriptions {
      id
      expired
      createdAt
      updatedAt
      package {
        id
        packageName
        packageType
        subscriptionDuration
        createdAt
      }
    }
  }
`;
export const USER_PROFILE = gql`
  query userProfile($username: String!) {
    userProfile(username: $username) {
      user {
        username
        age
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const USER_PROFILE_PUBLIC = gql`
  query userProfilePublic($username: String!) {
    userProfilePublic(username: $username) {
      username
        age
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
     }
  }
`;

export const SOCIAL_AUTH_GOOGLE = gql `mutation socialMediaRegisterGoogle($input:String!, $addr: String!,$userType: String!,$preference: String!){
  socialMediaRegisterGoogle(
    input: $input, addr: $addr,userType:$userType,preference:$preference
  ) {
    errors {
        field
        message
    }
    token
    user {
        authSource
        profilePic
        username
        email
        profileSetupStep
        profileComplete
        photos {
            id
            image
            unsafe
        }
        base_profile {
            id
            firstName
            lastName
        }
        user_basic{
          location
        }
    }
    credit{
        id
        balance
        flirtons
        flirtonsAvailable
      }
  }
}`

export const UPDATE_LOC = gql`
  mutation updateUserAddr($addr: String!) {
    updateUserAddr(addr: $addr)
  }
`;
export const USER_REGISTER = gql `
    mutation register($options:UsernamePasswordInput!,$addr: String!,$userType:String!,$preference:String!,$modelData:ModelRegisterFields){
        register(options:$options, addr:$addr,userType:$userType,preference:$preference,modelData:$modelData){
            errors{
                field
                message
            }
            user{
              profilePic
                username
                email
                usrType
                profileSetupStep
                profileComplete
                photos {
                    id
                    image
                    unsafe
                }
                base_profile {
                    id
                    firstName
                    lastName
                }
                user_basic{
                  location
                }
            }
            credit{
                id
                balance
                flirtons
                flirtonsAvailable
            }
            token
        }
    }
`
export const UPDATE_PROFILE_PIC = gql `mutation updateProfilePic($file: Upload!) {
  updateProfilePic(file: $file)
}
`
export const BASIC_SELCETIONS = gql `query{
  basicInfoSelections{
    hairColorTypes
    genders
    preferences
  }
}`
export const ADD_BASIC_INFO = gql `mutation addBasicInformation($options:BasicInfoInput!){
  addBasicInformation(options:$options)
}`
export const VERIFY_OTP = gql `mutation verifyOtp($otp:String!){
  verifyOtp(otp:$otp){
      user{            
          profilePic
          username
          email
          age
          usrType
          profileSetupStep
          profileComplete
          photos {
              id
              image
              unsafe
          }
          base_profile {
              id
              firstName
              lastName
          }
          user_basic{
            location
          }
      }
      credit{
          id
          balance
          flirtons
          flirtonsAvailable
      }
  }
}`