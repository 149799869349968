import { ApolloClient, HttpLink, InMemoryCache,split } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import store from './store'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import env from './env'
const production = env.production
const httpEndpoint = production ? `https://porntool.live/graphql` : `http://localhost:4000/graphql`
const websocketLink = production ? `wss://porntool.live/subscriptions` : `ws://localhost:4000/subscriptions`

const jtwToken = `Bearer ${store.state.core.token}`
const httpLink = new HttpLink({
    // You should use an absolute URL here
    uri: httpEndpoint,
    headers:{
      authorization:jtwToken
    }
  })

  const uploadLink = createUploadLink({
    // You should use an absolute URL here
    uri: httpEndpoint,
    headers:{
      authorization:jtwToken
    }
  })
  
  
  // Create the subscription websocket link
  const wsLink = new WebSocketLink({
    uri: websocketLink,
    options: {
      reconnect: true,
      connectionParams: () => {
        return { Authorization:  `Bearer ${jtwToken}` };
        },
    }
    
  })
  const authLink = setContext(async(_, { headers }) => {
    // Use your async token function here:
    // const token = `Bearer ${jtwToken}`
    // const token = store.state.core.flirtok ? `Bearer ${JSON.parse(localStorage.getItem('vuex')).core.flirtok}` : ''
        // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: jtwToken 
        }
    }
})
  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' &&
        operation === 'subscription'
    },
    wsLink,
    httpLink,
    authLink,
    uploadLink
  )
  console.log(link)
  // console.log(createUploadLink)
  // Create the apollo client
  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })

  const apolloClientUpload = new ApolloClient({
    link:createUploadLink({
      uri: httpEndpoint,
      headers:{
        authorization:jtwToken
      }
    }),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })

  export const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
    clients: {
      uploadClient:apolloClientUpload
    },
  })