<template>
    <div class="gallery-slider">
      <div class="carousel">
        <div class="carousel-track" ref="carouselTrack">
          <div class="carousel-slide" v-for="(image, index) in visibleImages" :key="index">
            <div class="image-container justify-center flex">
              <img :src="image.src" :alt="image.alt" class=" h-60 w-60 object-cover" />
            </div>
          </div>
        </div>
        <!-- <button class="carousel-prev" @click="prevSlide">Previous</button>
        <button class="carousel-next" @click="nextSlide">Next</button> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        images: [
          // Your image data here {
      
      {
        src: 'https://cdn.pixabay.com/photo/2016/03/09/14/46/woman-1246488_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2016/11/29/13/20/woman-1869791_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2016/07/09/19/20/girl-1506653_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2023/06/12/11/34/mushrooms-8058299_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2016/07/09/19/20/girl-1506653_1280.jpg',
        alt: 'Image 1'
      }, {
        src: 'https://cdn.pixabay.com/photo/2023/06/12/11/34/mushrooms-8058299_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2016/11/29/13/11/body-1869745_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2016/05/13/19/04/female-body-1390659_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2017/06/17/10/15/model-2411750_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2017/08/18/15/12/sea-2655289_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2016/05/13/19/04/female-body-1390659_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2016/05/13/19/04/female-body-1390659_1280.jpg',
        alt: 'Image 1'
      },
      {
        src: 'https://cdn.pixabay.com/photo/2017/06/17/10/15/model-2411750_1280.jpg',
        alt: 'Image 1'
      },
        ],
        visibleImages: [],
        currentIndex: 0,
        slideWidth: 0,
        autoSlideTimer: null
      };
    },
    methods: {
      updateVisibleImages() {
        const startIndex = this.currentIndex * 5;
        const endIndex = startIndex + 5;
        this.visibleImages = this.images.slice(startIndex, endIndex);
      },
      prevSlide() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        } else {
          this.currentIndex = Math.ceil(this.images.length / 4) - 1;
        }
        this.updateVisibleImages();
        this.slideWidth = this.$refs.carouselTrack.offsetWidth;
        this.resetAutoSlideTimer();
      },
      nextSlide() {
        if (this.currentIndex < Math.ceil(this.images.length / 4) - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
        this.updateVisibleImages();
        this.slideWidth = this.$refs.carouselTrack.offsetWidth;
        this.resetAutoSlideTimer();
      },
      resizeHandler() {
        this.updateVisibleImages();
        this.slideWidth = this.$refs.carouselTrack.offsetWidth;
      },
      startAutoSlide() {
        this.autoSlideTimer = setInterval(() => {
          this.nextSlide();
        }, 3000); // Change the interval as needed (in milliseconds)
      },
      stopAutoSlide() {
        clearInterval(this.autoSlideTimer);
      },
      resetAutoSlideTimer() {
        this.stopAutoSlide();
        this.startAutoSlide();
      }
    },
    mounted() {
      this.updateVisibleImages();
      this.slideWidth = this.$refs.carouselTrack.offsetWidth;
      window.addEventListener('resize', this.resizeHandler);
      this.startAutoSlide();
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.resizeHandler);
      this.stopAutoSlide();
    }
  };
  </script>
  
  <style scoped>
.gallery-slider {
  width: 100%;
  overflow: hidden;
}

.carousel {
  position: relative;
}

.carousel-track {
  display: flex;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.carousel-slide {
  flex: 0 0 calc(100% / 4);
}

.image-container {
  padding: 1rem;
  justify-content: center;
}

.image {
  width: 100%;
  height: auto;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  color: #000;
  cursor: pointer;
}

.carousel-prev {
  left: 1rem;
}

.carousel-next {
  right: 1rem;
}

/* Responsive Styles */

@media (max-width: 768px) {
  .carousel-slide {
    flex: 0 0 calc(100% / 2);
  }
}

@media (max-width: 576px) {
  .carousel-slide {
    flex: 0 0 100%;
  }
}
</style>
  