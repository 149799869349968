import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const core = {
  state: {
    userData: {},
    loggedIn: false,
    token: null,
    loading: false,
    tags:[],
    porntoolSubscriptions: [],
    hasActiveSubscription:false
  },
  mutations: {
    userData(state, data) {
      state.userData = data;
    },
    loggedIn(state, data) {
      state.loggedIn = data;
    },
    token(state, data) {
      state.token = data;
    },
    loading(state, data) {
      state.loading = data;
    },
    tags(state, data) {
      state.tags = data;
    },
    hasActiveSubscription(state, data) {
      state.hasActiveSubscription = data;
    },
    porntoolSubscriptions(state, data) {
      state.porntoolSubscriptions = data;
    },
  },
  actions: {},
  getters: {
    userData(state) {
      return state.userData;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
    token(state) {
      return state.token;
    },
    loading(state) {
      return state.loading;
    },
    tags(state) {
      return state.tags;
    },
    hasActiveSubscription(state) {
      return state.hasActiveSubscription;
    },
    porntoolSubscriptions(state) {
      return state.porntoolSubscriptions;
    },
  },
};

const store = createStore({
  modules: {
    core: core,
  },
  plugins: [
    createPersistedState({
      paths: [
        "core.userData",
        "core.loggedIn",
        "core.token",
        "core.tags",
        "core.porntoolSubscriptions",
        "core.hasActiveSubscription"
      ],
    }),
  ],
});

export default store;
