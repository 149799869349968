<template>
    <div class="gap-1 grid mb-3">
        <div v-for="subscription in subscriptions" :key="subscription.id"
          class="grid-cols-3 py-3 flex gap-3 flex-wrap  border-2 text-black border-red-600 px-2 rounded-lg"
          :class="{'bg-red-600 text-white':subscription.packageId == active}"
        >
          <div v-if="selectable">
            <input :value="subscription.packageId" v-model="active" type="radio" name="radio-1" class="radio border-2 border-red-500"  />
          </div>
          <div v-else>
            
          </div>
          <div class="text-lg font-sans">{{subscription.packageName}}</div>
          <div v-if="getDiscount(subscription) > 0"><p class="bg-red-500 text-white px-1 font-sans">{{getDiscount(subscription)}}% OFF</p></div>
          <div class="text-center text-xl pl-1 font-sans">
            ${{formatSubscriptionPrice(subscription)}} <span class="text-xs lg:text-sm font-sans">/Month</span>
          </div>
        </div>
      </div>
</template>

<script>
import { GET_ALL_SUBSCRIPTIONS } from '@/queries/main'
import helperMixin from '@/mixins/helpers'
export default {
  mixins:[helperMixin],
   props: {
      selectable: {
          type: Boolean,
          default: true
      }
    },
  data() {
    return {
      subscriptions :[],
      active:null
    }
  },
  async mounted() {
   try{
      this.showSpinner()
      const {data :{porntoolSubscriptions}} = await this.$apollo.query({
        query: GET_ALL_SUBSCRIPTIONS,
      })
      this.hideSpinner()
      this.subscriptions = porntoolSubscriptions
    this.active = porntoolSubscriptions[0].packageId
    }catch(e) {
      this.hideSpinner()
      console.log(e)
    }
  },
  methods:{
    formatSubscriptionPrice(subscription) {
      return `${subscription.price-1}.99`
    },
    getDiscount(subscription) {
      if(subscription.price == 5) {
        return 0
      }else if(subscription.price == 12) {
        return 25
      }else if(subscription.price == 30) {
        return 50
      }
    }
  }
}
</script>
5/12/30

