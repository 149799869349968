/* eslint-disable */
import App from "./App.vue";
// import { router } from "./router";
import { createApp} from "vue";
import store from './store'
import router from './router'
import './assets/styles.css'
import { FIREBASE_CONFIG } from './firebase.config.js'
import * as firebase from '@firebase/app';
// import { DefaultApolloClient } from "@vue/apollo-composable";
// import VueVideoPlayer from '@videojs-player/vue'
// import 'video.js/dist/video-js.css'
import { apolloProvider } from "./v-apollo";
firebase.initializeApp(FIREBASE_CONFIG)
// import authPlugin from "./auth/authPlugin";
const app = createApp(App);
app.use(apolloProvider)
app.use(store)
app.use(router)
// app.use(VueVideoPlayer)
// app.use(authPlugin);
// app.use(router);
app.mount("#app");
