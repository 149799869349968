<template>
  <div id="content">
    <div class="container home">
      <div class="video-loop">
        <div class="row no-gutters">
          <div class="order-1 order-sm-1 order-md-1 order-lg-1 order-xl-1 col-12 col-md-6 col-lg-6 col-xl-6">
          </div>
            <VideoComponent
              v-for="video in videos" :key="video.id"
              :video="video"
            />
        </div>
      <PaginationComponent
      v-if="pages"
      :pages="pages"
      :currentPage="currentPage"
      @changePage="changePage"
      />
      </div>
    </div>
  </div>
</template>

<script>
import VideoComponent from '@/components/small/VideoComponent.vue'
import PaginationComponent from '@/components/small/PaginationComponent.vue'
import { GET_ALL_MODELS_VIDEOS,GET_ALL_MODELS_VIDEOS_BY_TAGS } from '@/queries/main'
import helperMixin from '@/mixins/helpers'
export default  {
  props: {
    inputTags: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  mixins:[helperMixin],
  components: {
    VideoComponent,
    PaginationComponent
  },
  // apollo: {
  //   getAllModelVideos: {
  //     query: GET_ALL_MODELS_VIDEOS,
  //     variables() {
  //       return {
  //          page:this.page,
  //          itemsPerPage:5
  //       };
  //     },
  //     result({data :{getAllModelVideos}}) {
  //       this.videos = getAllModelVideos.media
  //       this.pages = getAllModelVideos.pages
  //       this.currentPage = getAllModelVideos.currentPage
  //       console.log(this.currentPage)
  //     },
  //   },
  // },
  mounted() {
    console.log(this.inputTags,"inputtt")
    this.getVideos()
  },
  data(){ 
    return {
      videos: [],
      pages:null,
      currentPage:null,
      page:1
    }
  },
  methods: {
    getVideos() {
      if(this.tags) {
        this.getAllModelVideosByTags()
      }else{ 
        this.getAllModelVideos()
      }
    },
  async getAllModelVideos() {
    try{
      this.showSpinner()
      const {data :{getAllModelVideos}} = await this.$apollo.query({
          query: GET_ALL_MODELS_VIDEOS,
          // fetchPolicy: "network-only",
          variables: {
            page:this.page,
            itemsPerPage:20
          },
        })
        this.hideSpinner()
        this.videos = getAllModelVideos.media
        this.pages = getAllModelVideos.pages
        this.currentPage = getAllModelVideos.currentPage
      }catch(e) {
        this.hideSpinner()
        console.log(e)
      }
    },
  async getAllModelVideosByTags() {
    try{
      this.showSpinner()
      const {data :{getAllModelVideosByTags}} = await this.$apollo.query({
          query: GET_ALL_MODELS_VIDEOS_BY_TAGS,
          // fetchPolicy: "network-only",
          variables: {
            page:this.page,
            itemsPerPage:20,
            tags:this.inputTags.length ? this.inputTags.join(',') : this.$store.state.core.tags.join(",")
          },
        })
        this.hideSpinner()
        this.videos = getAllModelVideosByTags.media
        this.pages = getAllModelVideosByTags.pages
        this.currentPage = getAllModelVideosByTags.currentPage
      }catch(e) {
        this.hideSpinner()
        console.log(e)
      }
    },
    changePage(page) {
      this.page = page
      this.getVideos()
    }
  },
  computed:{
    tags() {
      if(this.inputTags.length){ 
        return true
      }
      return this.$store.state.core.tags.length
    }
  },
   watch: {
    tags () {
      this.page = 1
      this.currentPage = 1
      this.getVideos()
    }
  }
}
</script>
