<template>
  <div v-if="modelClub" class="w-full grid px-2 grid-cols-2 z-50 gap-0 max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12"
      style="background-image: url('');">
      <div class="border-4 w-36 lg:w-52 "> <img :src="getUrlMedia(modelData.profilePic)"
          class="h-full w-full  bordered border-x-2 object-cover border-gray-50" alt=""> </div>
        <h1
        class="max-w-lg mb-1  gap-2  text-4xl font-bold flex flex-wrap tracking-tight font-sans text-white  sm:text-6xl sm:leading-none">
        JOIN<br class="hidden md:block" /><br>
        <span class=" text-red-600 sm:text-4xl text-2xl mt-1 lg:mt-3 "> {{modelData.username}}'s</span>
        <span class=" text-white  ">FANCLUB</span>
        <p class="max-w-xl mb-4 text-base mt-4 font-sans  text-gray-50 md:text-3xl" style="width: 300px;">
          {{modelData.username}} <br>
          <!-- <span class="bg-red-600 bg-rounded-sull px-2 mt-2 border-2 text-xl">Canada</span> -->
          <span class=" bg-rounded-sull px-2 mt-2  text-xl">Age {{modelData.age}}</span>
        </p>
      </h1>
      <div class=" mt-5 lg:py-1 ">
        <p class="text-white text-2xl font-sans italic" style="width: 300px;">You will get access for Videos from
          <span class=" text-yellow-400"> {{modelData.username}}</span></p>
        <ul class="pl-5 text-white mt-4" style="width: 300px;">
          <li class="flex flex-wrap font-sans gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
            </svg>
            Unlock 2,421 studios in one subscription</li>
          <li class="flex flex-wrap font-sans gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="none" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
            </svg>

            Quality you deserve — full HD and 4K</li>
          <li class="flex flex-wrap font-sans gap-2"> <svg xmlns="http://www.w3.org/2000/svg" fill="#FF69B4" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="none" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg>
            Find your fetish among 436,052 videos</li>
        </ul>
      </div>
    </div>
    <div v-else class=" mt-5 lg:py-1 ">
      <p class="text-white text-4xl px-2 font-sans italic w-10/12" > <span class=" uppercase font-sans font-bold text-yellow-500 text-7xl lg:text-9xl"> get </span><span class="text-white  uppercase font-bold text-6xl pl-5"> access</span><br> To  Full Videos 
        
      </p>
      <ul class="pl-5 text-white mt-4" style="width: 300px;">
        <li class="flex flex-wrap font-sans gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round"
              d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
          </svg>
          Unlock 2,421 studios in one subscription</li>
        <li class="flex flex-wrap font-sans gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="none" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
          </svg>

          Quality you deserve — full HD and 4K</li>
        <li class="flex flex-wrap font-sans gap-2"> <svg xmlns="http://www.w3.org/2000/svg" fill="#FF69B4" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="none" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
          </svg>
          Find your fetish among 436,052 videos</li>
          <li class="flex flex-wrap font-sans gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="none" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
          </svg>

          Quality you deserve — full HD and 4K</li>
          <li class="flex flex-wrap font-sans gap-2"> <svg xmlns="http://www.w3.org/2000/svg" fill="#FF69B4" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="none" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
          </svg>
          Find your fetish among 436,052 videos</li>
      </ul>
    </div>
</template>
<script>
import {USER_PROFILE_PUBLIC} from '@/queries/main'
import helperMixin from '@/mixins/helpers'
export default {
  mixins:[helperMixin],
  data() {
    return {
      modelClub:false,
      modelData:null
    }
  },
  async mounted() {
    let username = this.$route.query.model
    if(username) {
       const {data :{userProfilePublic}} = await this.$apollo.query({
        query: USER_PROFILE_PUBLIC,
        variables:{
          username:username
        }
      })
      if(userProfilePublic) {
        this.modelClub = true
        this.modelData = userProfilePublic
        console.log(this.modelData)
      }
    }
  }
}
</script>
