<template>
  <div class="landingvideo transition duration-300 transform border-2 hover:scale-105" v-on:mouseenter="showPreview('in')" v-on:mouseleave="showPreview('leave')">
    <div class="relative mb-0 sm:mx-auto md:mb-10 md:max-w-md lg:max-w-lg">
      <img v-if="!videoPreview" class="object-contain w-full h-24 rounded shadow-none  border-2 md:h-64 lg:h-48 lg:w-full"
        :src="getUrl(video.thumbnail)" alt="" />
      <video v-if="videoPreview" class="object-contain w-full h-36 rounded shadow-lg md:h-64 lg:h-48" autoplay="" loop=""
        muted="" preload="none">
        <source :src="getPreviewVideo" type="video/mp4">
      </video>

      <router-link :to="'/video/' + video.id" aria-label="Play Video"
        class="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300">
        <div v-if="!videoPreview"
          class="flex items-center justify-center w-10 h-10 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
          <svg class="w-6 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z">
            </path>
          </svg>
        </div>
      </router-link>

    </div> <router-link :to="'/video/' + video.id" aria-label="Play Video">
      <div class="bg-gray-100 py-2 h-16 justify-center font-sans uppercase  text-left px-3    " style="font-size: 8px;"><span
          class="title">{{ video.title }}</span>
        <div class="video-data text-left">
          <!-- <span class="views-number text-center">10848 views</span> -->
          Video length: <span class="duration font-sans font-bold text-red-50 bg-gray-500 px-2">{{ duration }}</span>
        </div>


      </div>
    </router-link>
  </div>
</template>
<script>
import helperMixin from '@/mixins/helpers'

export default {
  mixins: [helperMixin],
  props: ['video'],
  data() {
    return {
      previewTimeout: null,
      videoPreview: false,
      imageLoaded: false
    }
  },
  methods: {
    showPreview(data) {
      console.log(data)
      if (data == 'in') {
        this.previewTimeout = setTimeout(() => {
          if (this.previewTimeout) {
            this.videoPreview = true
            console.log("INITATING PREVIEW OUT FOR", this.video.duration)
          }
        }, 1000)
      }
      if (data == 'leave') {
        this.videoPreview = false
        clearTimeout(this.previewTimeout)
      }
    },
  },
  computed: {
    duration: function () {
      let time = this.video.duration
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time - minutes * 60);
      return `${minutes}:${seconds}`
    },
    getPreviewVideo: function () {
      return this.video.preview_url ? this.getUrl(this.video.preview_url)
        : 'https://dw.rdtcdn.com/media/videos/201203/01/148364/360P_360K_148364.mp4'
    }
  }
}
</script>
<!-- <template>
  <div class="landingvideo"
    v-on:mouseenter="showPreview('in')"
    v-on:mouseleave="showPreview('leave')"
  >
    <div class="relative mb-0 sm:mx-auto md:mb-10 md:max-w-md lg:max-w-lg">
      <img v-if="!videoPreview" class="object-cover w-full h-36 rounded shadow-lg md:h-64 lg:h-36"
        :src="getUrl(video.thumbnail)"
        alt="" />
      <video v-if="videoPreview" class="object-cover w-full h-36 rounded shadow-lg md:h-64 lg:h-36" autoplay="" loop="" muted="" preload="none">
        <source :src="getPreviewVideo" type="video/mp4">
      </video>
      <router-link :to="'/video/'+video.id" aria-label="Play Video"
        class="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-30 group hover:bg-opacity-25">
        <div v-if="!videoPreview" class="flex items-center justify-center w-10 h-10 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
          <svg class="w-6 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
            <path d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z">
            </path>
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import helperMixin from '@/mixins/helpers'

export default {
  mixins: [helperMixin],
  props: ['video'],
  data() {
    return {
      previewTimeout: null,
      videoPreview: false,
      imageLoaded: false,
      videosPerPage: 5, // Number of videos to display per page
      currentPage: 1, // Current page number
    }
  },
  methods: {
    showPreview(data) {
      console.log(data);
      if (data === 'in') {
        this.previewTimeout = setTimeout(() => {
          if (this.previewTimeout) {
            this.videoPreview = true;
            console.log("INITIATING PREVIEW OUT FOR", this.video.duration);
          }
        }, 1000);
      }
      if (data === 'leave') {
        this.videoPreview = false;
        clearTimeout(this.previewTimeout);
      }
    },
  },
  computed: {
    duration() {
      let time = this.video.duration;
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time - minutes * 60);
      return `${minutes}:${seconds}`;
    },
    displayedVideos() {
      const startIndex = (this.currentPage - 1) * this.videosPerPage;
      const endIndex = startIndex + this.videosPerPage;
      return this.videos.slice(startIndex, endIndex);
    },
    getPreviewVideo() {
      return this.video.preview_url ? this.getUrl(this.video.preview_url) : 'https://dw.rdtcdn.com/media/videos/201203/01/148364/360P_360K_148364.mp4';
    }
  }
}
</script> -->


