<template>
  <SpinnerComponent/>
  <MarqueeComponent/>
  <widget-container-modal />
   <!-- <AppHeader/> -->
    <div class="site" id="page">
      <NavBar/>
     <router-view></router-view>
      <AppFooter/>
    </div>
    <!-- #page we need this extra closing tag here -->
   <div class="modal fade wpst-user-modal" id="wpst-user-modal" tabindex="1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" data-active-tab="">
        <div class="modal-content">
          <div class="modal-body">
            <a href="#" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-remove"></i></a>
            <!-- Register form -->
            <div class="wpst-register">
              <div class="alert alert-danger">Registration is disabled.</div>
            </div>
            <!-- Login form -->
            <div class="wpst-login">
              <h3>Login to VTube Demo</h3>
              <form id="vtt_login_form" action="https://demo-vtt-adult.wp-script.com/" method="post">
                <div class="form-field">
                  <label>Username</label>
                  <input class="form-control input-lg required" name="vtt_user_login" type="text"/>
                </div>
                <div class="form-field">
                  <label for="vtt_user_pass">Password</label>
                  <input class="form-control input-lg required" name="vtt_user_pass" id="vtt_user_pass" type="password"/>
                </div>
                <div class="form-field lost-password">
                  <input type="hidden" name="action" value="vtt_login_member"/>
                  <button class="btn btn-theme btn-lg" data-loading-text="Loading..." type="submit">Login</button> <a class="alignright" href="#wpst-reset-password">Lost Password?</a>
                </div>
                <input type="hidden" id="login-security" name="login-security" value="38bf957945" /><input type="hidden" name="_wp_http_referer" value="/" />							
              </form>
              <div class="wpst-errors"></div>
            </div>
            <!-- Lost Password form -->
            <div class="wpst-reset-password">
              <h3>Reset Password</h3>
              <p>Enter the username or e-mail you used in your profile. A password reset link will be sent to you by email.</p>
              <form id="vtt_reset_password_form" action="https://demo-vtt-adult.wp-script.com/" method="post">
                <div class="form-field">
                  <label for="vtt_user_or_email">Username or E-mail</label>
                  <input class="form-control input-lg required" name="vtt_user_or_email" id="vtt_user_or_email" type="text"/>
                </div>
                <div class="form-field">
                  <input type="hidden" name="action" value="vtt_reset_password"/>
                  <button class="btn btn-theme btn-lg" data-loading-text="Loading..." type="submit">Get new password</button>
                </div>
                <input type="hidden" id="password-security" name="password-security" value="38bf957945" /><input type="hidden" name="_wp_http_referer" value="/" />							
              </form>
              <div class="wpst-errors"></div>
            </div>
            <div class="wpst-loading">
              <p><i class="fa fa-refresh fa-spin"></i><br>Loading...</p>
            </div>
          </div>
          <div class="modal-footer">
            <span class="wpst-register-footer">Don&#039;t have an account? <a href="#wpst-register">Sign up</a></span>
            <span class="wpst-login-footer">Already have an account? <a href="#wpst-login">Login</a></span>
          </div>
        </div>
      </div>
    </div>
    <!-- Other scripts -->
</template>

<script>

// import AppHeader from '@/components/AppHeader.vue'
// import NavBar from '@/components/NavBar.vue'
import AppFooter from '@/components/AppFooter.vue'
 import {container} from "jenesius-vue-modal";
import SpinnerComponent from '@/components/small/SpinnerComponent.vue'
import helperMixin from './mixins/helpers'
import MarqueeComponent from '@/components/small/MarqueeComponent.vue'
export default {
  mixins:[helperMixin],
  created() {
    this.setUserSubscriptions()
  },
  components: {
    // AppHeader,
    // NavBar,
    MarqueeComponent,
    AppFooter,
    SpinnerComponent,
    WidgetContainerModal: container
  }
}
</script>
 
<style>
.modal-container{
  z-index: 99999;
  background-color: #3e3e3e21;
}
#spinner {
   z-index: 9999999;
  position: fixed;
  top: 0; left: 0; 
  width: 100vw; height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s;
}
 
/* (B) CENTER LOADING SPINNER */
#spinner img {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%);
}
 
/* (C) SHOW & HIDE */
#spinner {
  visibility: hidden;
  opacity: 0;
}
#spinner.show {
  visibility: visible;
  opacity: 1;
}
  @import './assets/css/block-library.css';
  @import './assets/css/font.css';
  @import './assets/css/style.css';
  @import './assets/css/theme.css';
  @import './assets/css/custom.css';


.video-block .video-debounce-bar {
     background-color: #de2600;
}
 a, a:hover, .logo-word-1, ul#menu-footer-menu li a:hover, .required, #show-sharing-buttons:hover i, .tags-letter-block .tag-items .tag-item a:hover, .post-like a:hover i {
     color: #de2600;
}
 .btn, .btn-primary, .post-navigation a, .btn:hover, .btn-grey-light:hover, .membership ul li a:hover, #filters .filters-options span a:hover {
     background-color: #de2600!important;
     color: #FFFFFF!important;
     border-color: #de2600!important;
}
 @-webkit-keyframes glowing {
     0% {
         border-color: #de2600;
         -webkit-box-shadow: 0 0 3px #de2600;
    }
     50% {
         -webkit-box-shadow: 0 0 20px #de2600;
    }
     100% {
         border-color: #de2600;
         -webkit-box-shadow: 0 0 3px #de2600;
    }
}
 @-moz-keyframes glowing {
     0% {
         border-color: #de2600;
         -moz-box-shadow: 0 0 3px #de2600;
    }
     50% {
         -moz-box-shadow: 0 0 20px #de2600;
    }
     100% {
         border-color: #de2600;
         -moz-box-shadow: 0 0 3px #de2600;
    }
}
 @-o-keyframes glowing {
     0% {
         border-color: #de2600;
         box-shadow: 0 0 3px #de2600;
    }
     50% {
         box-shadow: 0 0 20px #de2600;
    }
     100% {
         border-color: #de2600;
         box-shadow: 0 0 3px #de2600;
    }
}
 @keyframes glowing {
    0% {
        border-color: #de2600;
        box-shadow: 0 0 3px #de2600;
  }
    50% {
        box-shadow: 0 0 20px #de2600;
  }
    100% {
        border-color: #de2600;
        box-shadow: 0 0 3px #de2600;
  }
}

    
</style>
