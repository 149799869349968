<template>
  <div
    class="flex h-screen w-full items-center justify-center bg-white bg-cover bg-no-repeat"
    style="background-image: url('/assets/images/backgroundimagebg.jpg')"
  ><img src="@/assets/images/backgroundimage/bg.jpg" class="absolute inset-0 object-cover w-full h-full" alt="" />
  <!-- <div><img src="@/assets/images/backgroundimage/bg.jpg" alt=""></div> -->
    <div
      class="rounded-xl bg-gray-50 bg-opacity-100 px-5 py-10 shadow-lg backdrop-blur-md max-sm:px-8"
    >
      <div class="text-white">
        <!-- package section -->
        <div class=" flex  justify-end">
          <!-- <p class="text-pink-500 font-sans font-bold uppercase py-3 text-lg">
            Get Creditss
          </p> -->
           <a @click.prevent="close" href="#" class="text-red-500 font-sans font-bold uppercase py-3 text-lg flex flex-wrap -mt-8">
            close <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

          </a>
        </div>
      <!-- <MembershipItemsVue
      :selectable="false"
      /> -->


      <div>

<h1 class="text-xl text-center pb-3 font-sans "> <span class="text-black font-bold text-3xl">PORN</span><span class="text-red-500 font-bold text-3xl">TOOL</span>  <br>
   <span class="text-black uppercase">USer LOGIN</span></h1>
      
      </div>
        <!-- package section -->
        <form action="# mt-5">
          <div class="mb-3">
            <p class="text-gray-900 py-1 text-sm font-sans font-bold uppercase text-center">
              or <router-link to="/register" class="text-red-500 underline">  Create Account</router-link>
            </p>
          </div>
          <div class="mb-4 text-lg">
            <input
              class="rounded-lg border-2 border-red-500 bg-pink-400 bg-opacity-50 px-6 py-2 w-full text-left text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
              type="text"
              name="name"
              placeholder="id@email.com"
              v-model="usernameOrEmail"
            />
          </div>

          <div class="mb-4 text-lg">
            <input
              class="rounded-lg border-2 border-red-500 bg-pink-400 bg-opacity-50 px-6 py-2 text-left w-full text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
              type="Password"
              name="name"
              placeholder="*********"
               v-model="password"
            />
          </div>
          <div>
            <p class="text-red-500 text-2xs font-bold  uppercase text-center font-sans">
              <!-- <input type="checkbox" id="myCheckbox" class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" required> -->

  Forgot Password?
              <a href="https://flirttool.com/initial-reset" to=""
                ><span class="underline font-sans font-bold">
                  REST</span
                ></a
              >
            </p>
          </div>
          <div class="mt-8 flex justify-center text-lg text-black">
            <button
              @click.prevent="login"
              class="rounded-lg bg-red-600 bg-opacity-100 font-sans px-10 py-2 text-white shadow-xl backdrop-blur-md transition-colors btn-lg duration-300 hover:bg-yellow-600 w-full uppercase"
            >
              Login
            </button>
          </div>
          <div class="">
            <div class="mt-2 text-center justify-center pl-4">
              <p class="text-gray-500 font-sans font-medium uppercase mt-1 flex flex-wrap text-2xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="green"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                  />
                </svg>
                <span class="mt-0 font-sans">
                  No Adult Related Trascation in your bank account</span
                >
              </p>
            </div>
            <div class="mt-2 pl-4 text-center justify-center">
              <p class="text-gray-800 flex flex-wrap text-center text-2xs">
                <span class="mt-0 font-sans">
                  You will gain access to all videos and updates available to
                  <br />
                  <span class="font-sans text-red-500 font-bold uppercase"
                    >PUSSY DELUXE
                  </span>
                  fanclub members for 1 month <br />
                  Total Charge $1.99</span
                >
              </p>
            </div>
          </div>
          <div>
            <img
              src="@/assets/VISA.png"
              alt=""
              class="h-16 justify-center mx-auto"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {closeModal} from "jenesius-vue-modal";
import {USER_LOGIN} from "@/queries/main"
import helperMixin from '@/mixins/helpers'
import MembershipItemsVue from '../small/MembershipItems.vue';
export default {
  mixins:[helperMixin],
  components:{
    MembershipItemsVue
  },
  data() {
    return {
      usernameOrEmail: "",
      password: ""
    }
  },
  methods: {
    async login() {
      if(!this.validateFields()) return 
      try {
        this.showSpinner()
        let { data : {login} } = await this.$apollo
          .mutate({
            mutation: USER_LOGIN,
            variables: {
              addr: "",
              usernameOrEmail: this.usernameOrEmail,
              password: this.password,
            },
          })
        this.hideSpinner()
        if(login.user) {
          const { user, token } = login;
          this.$store.commit("userData", user);
          this.$store.commit("token", token);
          this.$store.commit("loggedIn", true);
          closeModal()
          window.location.reload()
          return
        }
        alert("Please enter valid credentials")
      }catch(e) {
        this.hideSpinner()
        alert(e)
        console.log(e)
      }
    },
    close() {
      closeModal()
    },
    validateFields() {
      if(this.usernameOrEmail.length < 1) {
        alert('Please enter a valid email or username ')
        return false
      }
      if(this.password.length < 1) {
        alert('Please enter a valid password')
        return false
      }
      return true
    }
  }
}
</script>
<style>
input {
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px; 
}
</style>
