<template>
  <div class="hero">
          <div class="container" tabindex="-1">
            <div class="hero-text">
              <p></p>
            </div>
          </div>
        </div>
      <div id="wrapper-footer">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-12">
              <footer class="site-footer" id="colophon">
                <div class="footer-menu-container">
                  <div class="menu-footer-menu-container">
                    <ul id="menu-footer-menu" class="menu">
                      <li id="menu-item-18" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-18"><a href="/">Home</a></li>
                      <!-- <li id="menu-item-18" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-18"><a href="https://demo-vtt-adult.wp-script.com/18-u-s-c-2257/">18 U.S.C 2257</a></li> -->
                      <li id="menu-item-19" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-19"><a href="/dmca">DMCA</a></li>
                      <li id="menu-item-20" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-20"><a href="/privacy">Privacy Policy</a></li>
                      <li id="menu-item-21" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21"><a href="/terms">Terms of Use</a></li>
                    </ul>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="site-info font-sans text-sm">
                  2023 - PornTool. All rights reserved. Powered by Dialogmakers					
                </div>
                <!-- .site-info -->
              </footer>
              <!-- #colophon -->
            </div>
            <!--col end -->
          </div>
          <!-- row end -->
        </div>
        <!-- container end -->
      </div>
</template>