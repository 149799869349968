<template>
    <div class="text-left text-sm px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <LandingTopnav />
        <h1 class=" flex justify-center mb-10">
                    <router-link  to="/" class="logo-text"  title="VTube Demo" itemprop="url">
                    <span class="logo-word-1">Porn </span>
                    <span class="logo-word-2">Tool</span>
                    </router-link>
                  </h1>
        <p>

            <span class="text-xl py-10 mb-5">Terms of Use</span> <br> <span>
By using or visiting our site, you agree to the terms and conditions contained herein and all future amendments and modifications.<br>

These terms and conditions are subject to change at any time and you agree be bound by all modifications, changes and revisions. <br>If you do not agree, then don’t use our site.<br><br>

Our website allows for uploading, sharing and general viewing various types of content allowing registered and unregistered users to share and view adult content, including sexually explicit images and video.<br><br>

The website may also contain certain links to third party websites which are in no way owned or controlled by us.<br> We assume no responsibility for the content, privacy policies, practices of any third party websites. We cannot censor or edit the content of third party sites.<br> You acknowledge that we will not be liable for any liability arising from your use of any third-party website.<br><br>

You affirm that you are at least eighteen (18) years of age and/or over the age of majority in the jurisdiction you reside and from which you access the website if the age of majority is greater than eighteen (18) years of age. <br>If you are under the age of 18 and/or under the age of majority in the jurisdiction you reside and from which you access the website, then you are not permitted to use the website.<br><br>

You agree that you will not post any content that is illegal, unlawful, harassing, harmful, threatening, abusive, defamatory, obscene, libelous, hateful, or racial.<br><br>

You also agree that you shall not post, upload or publish any material that contains viruses or any code designed to destroy, interrupt,<br> limit the functionality of, or monitor any computer.<br><br>

You agree that you will not post, upload nor publish content which is intentionally or unintentionally<br> violating any applicable local, state, national, or international law.<br><br>

You agree that you will not post, upload or publish content depicting illegal activity nor depict<br> any act of cruelty to animals; You agree not to use our site in any way that might expose us to criminal or civil liability.<br><br>

The content on our site cannot be used, copied, reproduced, distributed,<br> transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purpose whatsoever without or prior written consent.<br><br>

In submitting a video to our site, you agree that you will not submit material that is copyrighted or subject to third party proprietary rights, nor submit material that is obscene, illegal, unlawful, ,<br> defamatory, libelous, harassing, hateful or encourages conduct that would be considered a criminal offense.<br>
</span> </p>
    </div>
</template>

<script>

import LandingTopnav from '@/components/small/LandingTopnav.vue';


export default {
component:{ LandingTopnav} ,


}


</script>