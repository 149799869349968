<style>
.animation-ctn{
  text-align:center;
  margin-top:5em;
}

	@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 0px
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px
   
    }

    100% {
        stroke-dashoffset: 960px;
      
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px 
    }

    100% {
        stroke-dashoffset: 960px
    }
}

@keyframes colored-circle { 
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
    display: inline
}

/* .svg img {
    display: none
} */

.icon--order-success svg polyline {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
    -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
} </style>
<template>
    <!-- component -->
    <!-- component --> <!-- component -->
<!-- component -->
<div class="bg-white h-screen lg:py-10">
      <div class="bg-white p-6  md:mx-auto">
       
<div class="animation-ctn">
<div class="icon icon--order-success svg  justify-center mx-auto flex flex-row items-center  w-full sm:w-auto pb-4 sm:pb-0">
    <svg xmlns="http://www.w3.org/2000/svg" width="80px" height="80px">
  <g fill="none" stroke="#22AE73" stroke-width="2">
    <circle cx="40" cy="40" r="36" style="stroke-dasharray:240px, 240px; stroke-dashoffset: 480px;"></circle>
    <circle id="colored" fill="#22AE73" cx="40" cy="40" r="36" style="stroke-dasharray:240px, 240px; stroke-dashoffset: 480px;"></circle>
    <polyline class="st0" stroke="#fff" stroke-width="6" points="28.5,40.8 38.7,50.9 56.2,33.4 " style="stroke-dasharray:60px, 60px; stroke-dashoffset: 120px;"/>
  </g>
</svg>
        </div>
</div>

<div class="text-center">
            <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Done!</h3>
            <p class="text-gray-600 my-2">Thank you for completing your secure online payment.</p>
            <p> Have a great day!  </p>
            <div class="py-10 text-center">
                <a href="#" class="px-12 bg-gray-900 hover:bg-indigo-500 text-white font-semibold py-3">
                    GO BACK 
               </a>
            </div>
        </div>
    </div>
  </div>
    </template>