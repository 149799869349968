<template>
  <div class="slider lg:mb-36 mb-5 lg:-mt-0  -mt-24">
   
    <transition-group name="slide-left" tag="div" class="slides">
      <div class="slide" v-for="(image, index) in images" :key="index" :class="{ active: index === currentIndex }">
        <img :src="image.url" :alt="image.alt">
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        { url: require('@/assets/images/slider/banner.png'), alt: 'Image 1' },
        { url: require('@/assets/images/slider/banner2.jpg'), alt: 'Image 2' },
        { url: require('@/assets/images/slider/banner3.jpg'), alt: 'Image 1' },
        { url: require('@/assets/images/slider/banner4.jpg'), alt: 'Image 2' },
        { url: require('@/assets/images/slider/banner.png'), alt: 'Image 3' },
      ],
      currentIndex: 0,
      slideInterval: null,
      slideDuration: 3000,
    };
  },
  mounted() {
    this.startSlideShow();
  },
  beforeUnmount() {
    this.stopSlideShow();
  },
  methods: {
    startSlideShow() {
      this.slideInterval = setInterval(this.nextSlide, this.slideDuration);
    },
    stopSlideShow() {
      clearInterval(this.slideInterval);
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
  },
};
</script>

<style>
.slider {
  width: 1550px;
  height: 140px;
  max-width: 100%;
  margin: 0 auto;
}

.slides {
  position: relative;
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.slide.active {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.3s ease;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}


</style>
