// Website
import { createWebHistory, createRouter } from 'vue-router'




// Guards
import { authGuard } from '../utils/helpers'
import Home from '@/views/AppHome.vue'
import Video from '@/views/SingleVideo.vue'
import MembershipPage from '@/views/MembershipPage'
import PaymentSuccess from '@/views/PaymentSuccess'
import PaymentFailed from '@/views/PaymentFailed'
import FanClub from '@/views/FanClub.vue'
import UserRegister from '@/views/UserRegister.vue'
import UserAvatar from '@/views/UserAvatar.vue'
import UserDetail from '@/views/UserDetail.vue'
import OtpVeri from '@/views/OtpVeri.vue'
import LanDing from '@/views/LanDing.vue'
import MobileLanding from '@/views/MobileLanding.vue'
import UserOtp from '@/views/UserOpt.vue'
import PeerTest from '@/views/PeerTest.vue'
import ModelCam from '@/views/ModelCam.vue'
import LiveCam from '@/views/LiveCam.vue'
import OnlineModels from '@/views/OnlineModels.vue'
import LiveTheme from '@/views/LiveTheme.vue'
import PtModels from '@/views/PtModels.vue'
import TermsOfuse from '@/views/TermsOfuse.vue'
import PrivayPolicy from '@/views/PrivayPolicy.vue'
import DcMa from '@/views/DcMa.vue'











const routes = [
    { path: '/home', component: Home ,name:'home'},
    { path: '/peer', component: PeerTest ,name:'PeerTest'},
    { path: '/modelcam/:username', component: ModelCam ,name:'ModelCam'},
    { path: '/livecam/:username', component: LiveCam ,name:'LiveCam'},
    { path: '/onlinemodels', component: OnlineModels ,name:'OnlineModels'},
    {path: '/live', component: LiveTheme , name:'LiveTheme'},
    {path: '/models' , component:PtModels , name:'PtMOdels'},
    {path:'/terms', component:TermsOfuse, name:'TermsOfuse'},
    {path: '/privacy', component: PrivayPolicy, name:'PrivayPolicy'},
    {path: '/dmca', component: DcMa, name:'DcMa'},




    // { path: '/', component: Home ,name:'home'},
    // { path: '/', component: Home ,name:'home'},
    { path: '/register', component: UserRegister ,name:'UserRegister'},
    { path: '/avatar', component: UserAvatar ,name:'UserAvatar',beforeEnter: (to, from, next) => {
      authGuard(to, from, next)
    }},
    { path: '/detail', component: UserDetail ,name:'UserDetail',beforeEnter: (to, from, next) => {
      authGuard(to, from, next)
    }},
    { path: '/verify', component: UserOtp ,name:'UserOtp',beforeEnter: (to, from, next) => {
      authGuard(to, from, next)
    }},


    { path: '/video/:id', component: Video ,name:'Video'},
    { path: '/membership', component: MembershipPage, Name: 'MembershipPage'},
    { path: '/success', component: PaymentSuccess ,name:'PaymentSuccess'},
    { path: '/error', component: PaymentFailed ,name:'PaymentFailed'},


    {path: '/fanclub', component: FanClub, name:'FanClub'},
    {
      path:'/register', component: UserRegister, name:'UserRegister'
    },


    {
      path: '/Uploadpicture', component: UserAvatar, name:'UserAvatar'
    },

    {path: '/userdetail', component: UserDetail, name:'UserDetail'} ,
    {path: '/opt' , component: OtpVeri, name:'OptVeri'},
    {path: '/', component: LanDing, name:'LanDing'},
    {path: '/moblanding', component: MobileLanding, name:'Mobilelanding'},

    {path: '/fanclub', component: FanClub, name:'FanClub'}

]

const router = createRouter({
    history: createWebHistory(),
    routes,
  })

export default router