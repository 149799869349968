<template>
  <div class="conatiner">
    <div class="lg:grid-cols-12 grid-cols-1  flex px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div class="lg:col-span-6">
        <h1>ModelCam</h1>
        <h1>Online clients</h1>

         <a style="margin-left:5px" v-for="connectedClient in connectedClients" :key="connectedClient.connectionId"><strong>{{connectedClient.metadata.username}}</strong></a>
         <video ref="myVideo"></video>
      </div>
      <div class="lg:col-span-6" style="margin-left:4rem">
        <h1>messages</h1>
        <div v-for="(message,index) in messages" :key="index" class="message" :class="{ f_right: message.username == $store.state.core.userData.username }">
          <img :src="`https://flirttool.com/media/${message.profilePic}`">
          <p>{{message.message}}</p>
        </div>
      </div>
      </div>
       <div class="lg:grid-cols-12   flex px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="lg:col-span-12" style="margin-left:4rem">
          <h1>send message</h1>
          <textarea class="textarea" v-model="message"></textarea>
          <br>
          <button @click.prevent="sendMessage" class="inline-flex items-center bg-red-500 font-sans justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:bg-red-400 hover:text-red-500">Send message</button>
        </div>
      </div>
  </div>
</template>

<script>
/* eslint-disable */
// const socket = window.io("http://localhost:5000/")
const user = 'Vuemodel'
// import  Peer  from 'peerjs-client'
import axios from '@/axios'
import env from '@/env'
const PRODUCTION = false
export default {
  data(){ 
    return {
      peer:null,
      connectedClients:[],
      myVideoStream:null,
      messages:[],
      message:""
    }
  },
  mounted () {
    let modelRoom = this.$route.params.username;
   
    var peer = new Peer({
      host: PRODUCTION ? 'cam.porntool.live' : 'localhost', 
      ...(!PRODUCTION) && {port: 5000},
      ...(PRODUCTION) && {port: 443},
      path: '/peerjs',
      config: {
        'iceServers': [
          {
            urls: "stun:a.relay.metered.ca:80",
          },
          {
            urls: "turn:a.relay.metered.ca:80",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
          {
            urls: "turn:a.relay.metered.ca:80?transport=tcp",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
          {
            urls: "turn:a.relay.metered.ca:443",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
          {
            urls: "turn:a.relay.metered.ca:443?transport=tcp",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
        ]
      },
      metadata:{name:'sumit'},
      debug: 3
    });
    this.peer = peer
    peer.on("open", (id) => {
      console.log('my id is' + id);
      this.updatePeerMeta(id)
      // socket.emit("join-room", modelRoom, id, user);
    });
    peer.on("close", (id) => {
      console.log("closed")
      // alert("peer closed")
    });
    peer.on("disconnected", (id) => {
      console.log("disconnected")

      alert("peer disconnected")
    });
    peer.on("error", (id) => {
      console.log("error")
      // alert("peer error")
    });
    this.initConnectionEvents()
    
    

    

    const addVideoStream = (video, stream) => {
      video.srcObject = stream;
      video.addEventListener("loadedmetadata", () => {
        video.play();
      });
    };

    const myVideo = this.$refs.myVideo
     myVideo.controls = true
     const constraints = {
       video: {
        width: {  ideal: 1280, max: 1920 },
        height: {  ideal: 720, max: 1080 },
        frameRate: { ideal: 20, max: 1000 }
      },
      audio:true
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        console.log(stream)
        this.myVideoStream = stream;
        addVideoStream(myVideo, stream);

        // peer.on("call", (call) => {
        //   console.log('someone call me');
        //   call.answer(stream);
        //   const video = document.createElement("video");
        //   call.on("stream", (userVideoStream) => {
        //     addVideoStream(video, userVideoStream);
        //   });
        // });

        // socket.on("user-connected", (userId) => {
        //   console.log("comeone conne")
        //   connectToNewUser(userId, stream);
        // });
      });
  },
  methods: {
    initConnectionEvents() {
      const connectToNewUser = (userId, stream) => {
        console.log('I call someone' + userId);
        const call = this.peer.call(userId, stream);
      };
      this.peer.on('connection', (conn) => { 
        
        conn.on('open', ()=> {
          this.connectedClients.push(conn)
          
          
          conn.on('data', (data) => {
            this.messages.push(data)
            this.broadcastMessage(data)
            console.log(this.peer)
          });

          // // Send messages
          // conn.send('Hello!');
        });
        conn.on('close',()=>{
          const index = this.connectedClients.findIndex(connection => connection.connectionId == conn.connectionId)
          if (index > -1) { 
            this.connectedClients.splice(index, 1); 
          }
        })
        connectToNewUser(conn.peer,this.myVideoStream)
      });
    },
    broadcastMessage(data) {
      let allConnections = this.peer._connections
      console.log(allConnections)
      allConnections.forEach(connection => {
         let dataConnection = connection.find(thisConn => thisConn.type == 'data')
         console.log(dataConnection)
         if(dataConnection) {
            dataConnection.send(data)
         }
      });
     
    },
    sendMessage() {
      let message = {
        username:this.$store.state.core.userData.username,
        profilePic:this.$store.state.core.userData.profilePic,
        message:this.message
      }
      this.broadcastMessage(message)
      this.messages.push(message)
      this.message = ""
    },
    async updatePeerMeta(peerId) {
      try{
        let {data} = await axios.post('identify',{
          peerId:peerId
       })
       let username = data.UserInfo.username
       let usrType = data.UserInfo.usrType
       console.log(usrType,"usrType")
       console.log(this.$route.params.username != username)
       console.log(usrType != 'model')
       if(this.$route.params.username == username && usrType == 'model') {
        //  console.log(this.peer)
       }else{
         alert("not validated")
         this.$router.push('/')
       }
       console.log(username)
       console.log(data)
      }catch(e) {
        console.log(e)
      }
    }
  }
}

</script>
<style scoped>
.message {
  margin-top:5px
}
.message img {
  height:30px;
  display:inline-block
}
.message p {
  display:inline-block;
  margin-left:5px;
}
textarea {
    height: 86px;
  width: 500px;
  border: solid 2px gray;
}
.f_right {
  margin-left:350px
}
</style>