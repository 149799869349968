<template>
  <div>
    <notificationGroup group="top" position="top">
      <div
        class="fixed inset-0 z-10 flex items-start justify-end p-6 px-4 py-6 mt-0 pointer-events-none"
      >
        <div class="w-full max-w-sm"></div>
      </div>
    </notificationGroup>

    <div class="relative lg:-mr-16 lg:-ml-16 -mt-16">
      <img
        src="@/assets/profilesetup/register-page-bg.jpg"
        class="absolute inset-0 object-cover w-full h-full"
        alt=""
      />

      <div
        class="relative px-0 py-1 overflow-auto mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-0 bg-pink-500 bg-opacity-0"
      >
        <div
          class="px-0 py-1 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-8 lg:py-20"
        >
          <div
            class="flex flex-col items-left justify-start xl:flex-row inset-y-0 left-0"
          >
            <!-- Login Section -->
            <div
              class="px-4 py-3 sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-4 lg:py-1 inset-y-0 left-0"
            >
              <div
                class="flex flex-col justify-center bg-pink-50 shadow-2xl rounded-3xl opacity-95 px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-10 relative h-full lg:full"
              >
                <router-link to="/">
                  <div
                    class="flex flex-col flex-wrap content-center mt-3 mb-10"
                  >
                    <p
                      class="text-4xl uppercase font-sans italic text-center font-bold text-pink-500"
                    >
                      Register once, <br />
                      <span class="text-red-500"> use twice!</span>
                    </p>
                  </div>
                </router-link>
                <p
                  class="text-xl uppercase text-center font-sans text-pink-500"
                >
                  Flirttool Dating community
                </p>
                <div class="grid lg:grid-cols-2 gap-2 grid-cols-2 mt-10">
                  <div class=" ">
                    <img
                      class="lg:float-right lg:h-10 h-12"
                      :src="require('../assets/icons/PorntoolLogo.png')"
                      alt="Connect with flirttool & start your personal online dating adventure! "
                    />
                  </div>
                  <div>
                    <img
                      class="float-left lg:h-16 h-12 pl-5"
                      :src="require('../assets/logo-light.png')"
                      alt="Connect with flirttool & start your personal online dating adventure! "
                    />
                  </div>
                </div>

                <div
                  class="grid grid-cols-1 gap-2 mt-1 md:grid-cols-2 sm:grid-cols-2"
                >
                  <div
                    class="grid grid-cols-1 lg:pl-5 pl-8 -mt-2 mr-0 md:mr-0 justify-end"
                  >
                    <button
                    @click.prevent="socialLoginGoogle"
                      class="bg-white  py-1   btn-outline-none md:mt-10 xl:mt-10 lg:mt-10 lg:btn-lg hover:bg-pink-50 rounded-full shadow-2xl border-0 w-56 "
                    >
                      
                      <span
                        class="px-3 py-2 flex flex-wrap font-sans text-lg text-gray-700 dark:text-white"
                      >
                      <img
                        :src="
                          require('@/assets/google_logo_png_suite_everything_you_need_know_about_google_newest.png')
                        "
                        class="w-10 h-10 m "
                        alt="Connect with flirttool & start your personal online dating adventure!"
                      /> <span class="mt-1 pl-3">Google</span> 
                      </span>
                    </button>
                  </div>
                  <div class="grid grid-cols-1 -mt-2 pl-8 mr-0 md:mr-0">
                    <button
                     
                      class="bg-blue-500  py-1   btn-outline-none md:mt-10 xl:mt-10 lg:mt-10 lg:btn-lg hover:bg-blue-900 rounded-full shadow-2xl border-0 w-56 "
                    >
                      

                      <span
                        class="px-2 flex flex-wrap font-sans text-lg text-white dark:text-white"
                      ><img
                        :src="
                          require('@/assets/Facebook_f_logo_(2019).svg.webp')
                        "
                        class="w-10 h-10 m"
                        alt="Connect with flirttool & start your personal online dating adventure!"
                      />
                      <span class="mt-1 pl-3">Facebook</span> 
                      </span>
                    </button>
                  </div>
                </div>

                <form class="flex flex-col pt-3 md:pt-0">
                  <!-- first row start -->
                  <div
                    class="grid grid-cols-2 gap-5 mt-0 md:grid-cols-2 md:gap-8"
                  >
                    <div class="grid grid-cols-1 mt-0 mr-0 md:mr-0">
                      <label
                        class="mb-5 text-xs font-semibold text-left uppercase dark:text-gray-800 md:text-sm text-cyan-900"
                        >First Name</label
                      >
                      <input
                        v-model="firstName"
                        class="px-3 py-3 -mt-10 border-2 border-pink-300 rounded-lg dark:text-gray-800 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-transparent"
                        type="text"
                        placeholder="First Name"
                      />
                      <div v-if="firstName_e.length" class="pt-2 text-left text-red-500 error text-xs">
                        {{firstName_e}}
                      </div>
                      <!-- validation area start -->
                      <div class="pt-2 text-left text-red-500 error text-xs">
                        <!-- First name is required -->
                      </div>
                      <!-- end of validation area -->
                    </div>
                    <div class="grid grid-cols-1 mt-0 mr-0 md:mr-0">
                      <label
                        class="mb-5 text-xs font-semibold text-left uppercase dark:text-gray-800 md:text-sm"
                        >Last Name</label
                      >
                      <input
                      v-model="lastName"
                        class="px-3 py-3 -mt-10 border-2 border-pink-300 rounded-lg dark:text-gray-800 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                        type="text"
                        placeholder="Last Name"
                      />
                      <div v-if="lastName_e.length" class="pt-2 text-left text-red-500 error text-xs">
                        {{lastName_e}}
                      </div>
                      <div class="pt-2 text-left text-red-500 error text-xs">
                        <!-- Last name is required -->
                      </div>
                    </div>
                  </div>
                  <!-- first row end -->

                  <!-- first row start -->
                  <div
                    class="grid grid-cols-2 gap-5 mt-0 md:grid-cols-2 md:gap-8"
                  >
                    <div class="grid grid-cols-1 mt-0 mr-0 md:mr-0">
                      <label
                        class="mb-5 text-xs mt-3 font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-800"
                        >Username</label
                      >
                      <input
                      v-model="username"
                      class="px-3 py-3 -mt-10 border-2 border-pink-300 rounded-lg dark:bg-gray-700 dark:text-gray-800 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-transparent"
                        type="text"
                        placeholder="Username"
                      />
                      <div v-if="username_e.length" class="pt-2 text-left text-red-500 error text-xs">
                        {{username_e}}
                      </div>
                    </div>
                    <div class="grid grid-cols-1 mt-0 mr-0 md:mr-0">
                      <label
                        class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-800 mt-3"
                        >E-mail</label
                      >
                      <input
                      v-model="email"
                        class="px-3 py-3 -mt-10 border-2 border-pink-300 rounded-lg dark:bg-gray-700 dark:text-gray-800 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                        type="text"
                        placeholder="E-mail"
                      />
                      <div v-if="email_e.length" class="pt-2 text-left text-red-500 error text-xs">
                        {{email_e}}
                      </div>
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-2 gap-5 mt-0 md:grid-cols-2 md:gap-8"
                  >
                    <div class="grid grid-cols-1 mt-0 mr-0 md:mr-0">
                      <label
                        class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm mt-3 dark:text-gray-800"
                        >Password</label
                      >
                      <input
                      v-model="password"
                        class="px-3 py-3 -mt-10 border-2 border-pink-300 rounded-lg dark:bg-gray-700 dark:text-gray-800 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-transparent"
                        type="password"
                        placeholder="Password"
                      />
                      <div v-if="password_e.length" class="pt-2 text-left text-red-500 error text-xs">
                        {{password_e}}
                      </div>
                    </div>
                    <div class="grid grid-cols-1 mt-0 mr-0 md:mr-0">
                      <label
                        class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm mt-3 dark:text-gray-800"
                        >Confirm Password</label
                      >
                      <input
                      v-model="c_password"
                        class="px-3 py-3 -mt-10 border-2 border-pink-300 rounded-lg dark:bg-gray-700 dark:text-gray-800 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                        type="password"
                        placeholder="Confirm Password"
                      />
                     <div v-if="c_password_e.length" class="pt-2 text-left text-red-500 error text-xs">
                        {{c_password_e}}
                      </div>
                    </div>
                  </div>

                  <!-- first row end -->

                  <!-- second row start -->

                  <div class="pt-2 text-left text-red-500 error text-xs">
                    <label class="inline-flex items-center mt-3 text-left">
                      <input
                      v-model="acceptedTerms"
                        type="checkbox"
                        class="w-5 h-5 form-checkbox border-2 border-pink-700 text-pink-600"
                        checked
                      /><span
                        class="ml-2 uppercase text-gray-700 dark:text-gray-800"
                        >I agree to the terms, conditions and privacy policy
                        <a
                          class="text-blue-400"
                          href="https://flirttool.com/terms"
                          >Click Here To View Term</a
                        >
                      </span>
                    </label>
                  </div>
                  <!-- second row end -->
                  <div class="mt-4 mb-2 sm:mb-4">
                    <button
                    @click.prevent="doSignUp"
                      type="button"
                      class="inline-flex items-center bg-pink-500 justify-center w-full h-12 px-6 font-medium tracking-wide text-white font-sans text-lg btn-lg rounded-full uppercase transition duration-200 shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    >
                      Register
                    </button>
                  </div>
                </form>
                <p
                  class="mx-4 mb-0 text-xs font-semibold text-black text-center dark:text-gray-800 md:text-sm mt-4"
                >
                  Already have an account?
                  <router-link
                    to="/login"
                    class="font-semibold text-pink-500 underline"
                  >
                    Login Here.
                  </router-link>
                </p>
                <p
                  class="mt-0 mb-3 font-semi-bold text-center text-gray-500 uppercase text-2xs dark:text-gray-800 md:text-sm"
                >
                  MAZU™ Powered by Dialogmakers International Ltd. 2022<br />
                  <router-link to="/terms" class="text-pink-500">
                    Terms </router-link
                  >|<router-link to="/privacy" class="text-pink-500">
                    Privacy </router-link
                  >|<router-link to="/imprint" class="text-pink-500">
                    Imprint</router-link
                  >
                </p>
                <div class="pt-12 pb-2 text-center dark:text-gray-800"></div>
              </div>
            </div>
            <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
              <!-- <p class="absolute   text-left bottom-36 font-sans font-bold text-white  text-5xl
                ">    <span class="text-7xl  uppercase">Register once,</span> <br> <span class="font-sans">Use twice!</span></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { SOCIAL_AUTH_GOOGLE ,USER_REGISTER } from "@/queries/main";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import helperMixin from '@/mixins/helpers';

export default {
  mixins:[helperMixin],
  data: () => ({
    email: "",
    username: "",
    password: "",
    c_password: "",
    firstName: "",
    lastName: "",
    email_e: "",
    username_e: "",
    password_e: "",
    c_password_e: "",
    firstName_e: "",
    lastName_e: "",
    isValidated : false, 
    loadingRegister: false,
    acceptedTerms: false,
  }),
  created() {
    // if (this.$store.state.core.flirtok) {
    //   this.$router.push("/find-matches");
    // }
  },
  methods: {
    socialLoginGoogle() {
       if (!this.acceptedTerms) {
        alert('you need to agree with the terms before you contine')
        return;
      }

      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: "",
                input: result.user.accessToken,
                userType: "",
                preference: "",
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                // onLogin(createProvider().defaultClient);
                this.$store.commit("userData", user);
                
                  // this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("token", token);
                this.$store.commit("loggedIn", true);

                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  window.location.href = '/'
                } else {
                  this.$router.push("/avatar");
                }

                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          console.log("there was error")
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    validate() {
      this.isValidated = true
      if(this.firstName.length < 2){ 
        this.firstName_e = 'Please enter firstname with chars more than 2'
        this.isValidated = false
      }else {
        this.firstName_e = ''
      }
      if(this.lastName.length < 2){ 
        this.lastName_e = 'Please enter lastname with chars more than 2'
        this.isValidated = false
      }else {
        this.lastName_e = ''
      }
      if(this.username.length < 4){ 
        this.username_e = 'Please enter username with chars more than 4'
        this.isValidated = false
      }else {
        this.username_e = ''
      }
      if(!this.email.includes('@')){ 
        this.email_e = 'Please enter a valid email'
        this.isValidated = false
      }else {
        this.email_e = ''
      }
      if(this.password.length < 8){ 
        this.password_e = 'Please use password with more than 8 chars'
        this.isValidated = false
      }else {
        this.password_e = ''
      }
      if(this.c_password != this.password){ 
        this.c_password_e = 'Passwords donst match'
        this.isValidated = false
      } else {
        this.c_password_e = ''
      }
    },
    doSignUp() {
      if (!this.acceptedTerms) {
        alert('you need to agree with the terms before you contine')
        return;
      }
      this.validate()
      if(!this.isValidated) {
        return alert('Please fill the form correctly')
      }
      this.showSpinner()
      this.$apollo.mutate({
          mutation: USER_REGISTER,
          variables: {
              addr: '',
              options: {
                af_id: '',
                email: this.email,
                username: this.username,
                password: this.password,
                firstName: this.firstName,
                lastName: this.lastName,
              },
              userType:'',
              preference:'',
              modelData:null
          },
      }).then(({ data }) => {
        this.hideSpinner()
          console.log({ data: data, fail: false })
          console.log(data.register.errors)
          if(data.register.errors) {
            let error = data.register.errors[0].message
            alert(error)
          }else {
            const { user, token, credit } = data.register;
            this.$store.commit("userData", user);
            //this.$store.commit("setBalance", credit.balance);
            // this.$store.commit("setBalance", credit.balance + credit.flirtons)
            // this.$store.commit("setRandState", Math.random(100));
            this.$store.commit("token", token);
            this.$store.commit("loggedIn", true);
            this.$router.push("/avatar");
          }
      })
      .catch((e) => {
        this.hideSpinner()
        alert("there was an error registering user")
          console.log(Object.keys(e), "enumerate")
          console.log({ data: e, fail: true })
      });
    },
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>
