/* eslint-disable */
import axios from 'axios';
import store from './store';
import env from './env'
// const production = env.production
const production = true

axios.defaults.baseURL = production ? 'https://cam.porntool.live/api/' : "http://localhost:5000/api/"
axios.defaults.headers.common['authorization'] = `Bearer ${store.state.core.token}`

export default axios