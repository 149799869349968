<template>
  <div class="bg-black -mt-16 lg:-mr-16  lg:-ml-16 2xl:-mr-16 2xl:-ml-16 xl:-mr-16 xl:-ml-16 md:-mr-16 md:-ml-16">
    <div class="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div class="relative flex items-center justify-between">
        <div class="flex items-center">
          <a href="/" aria-label="Company" title="Company" class="inline-flex items-center mr-8">

            <img src="@/assets/images/Porntool.png" alt="" class="h-full md:h-16 lg:h-16">
          </a>
          <!-- <ul class="flex items-center hidden space-x-8 lg:flex">
          <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">Product</a></li>
          <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">Features</a></li>
          <li><a href="/" aria-label="Product pricing" title="Product pricing" class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">Pricing</a></li>
          <li><a href="/" aria-label="About us" title="About us" class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">About us</a></li>
        </ul> -->
        </div>
        <ul class="flex items-center  space-x-8 lg:flex">

          <li>
            <a href="/"
              class="inline-flex items-center text-3xl justify-center md:-mr-48 h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              aria-label="Sign up" title="Sign up">
              LOGIN
            </a>
          </li>
        </ul>
        <!-- Mobile menu -->
        <div class="lg:hidden">

          <!-- Mobile menu dropdown 
        <div class="absolute top-0 left-0 w-full">
          <div class="p-5 bg-white border rounded shadow-sm">
            <div class="flex items-center justify-between mb-4">
              <div>
                <a href="/" aria-label="Company" title="Company" class="inline-flex items-center">
                  <svg class="w-8 text-deep-purple-accent-400" viewBox="0 0 24 24" stroke-linejoin="round" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor" fill="none">
                    <rect x="3" y="1" width="7" height="12"></rect>
                    <rect x="3" y="17" width="7" height="6"></rect>
                    <rect x="14" y="1" width="7" height="6"></rect>
                    <rect x="14" y="11" width="7" height="12"></rect>
                  </svg>
                  <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">Company</span>
                </a>
              </div>
              <div>
                <button aria-label="Close Menu" title="Close Menu" class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <nav>
              <ul class="space-y-4">
                <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a></li>
                <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a></li>
                <li><a href="/" aria-label="Product pricing" title="Product pricing" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a></li>
                <li><a href="/" aria-label="About us" title="About us" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">About us</a></li>
                <li><a href="/" aria-label="Sign in" title="Sign in" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Sign in</a></li>
                <li>
                  <a
                    href="/"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    aria-label="Sign up"
                    title="Sign up"
                  >
                    Sign up
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        -->
        </div>
      </div>
    </div>
  </div>
  <div class="relative lg:-mr-16  lg:-ml-16 2xl:-mr-16 2xl:-ml-16 xl:-mr-16 xl:-ml-16 md:-mr-16 md:-ml-16 ">

    <img src="@/assets/images/4.jpg" class="absolute inset-0 object-cover w-full h-full" alt="" />
    <!-- <img
      src="@/assets/images/image1.jpg" class="absolute inset-0 object-cover  w-3/6 lg:3/6 xl:3/6 sm:3/6 md h-full"
      alt="" /> -->
    <img src="@/assets/images/image1.jpg"
      class="absolute lg:hidden inset-0 object-cover  w-full lg:3/6 xl:3/6 sm:3/6 md h-full" alt="" />
    <div class="relative bg-gradient-to-t from-black bg-opacity-100">
      <div class="px-1 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-1 lg:py-20 ">
        <div class="flex flex-col items-center justify-between xl:flex-row ">
          <FanclubComponent/>


          <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
            <div class="rounded-xl bg-gray-50 bg-opacity-100 px-5 py-1  shadow-lg backdrop-blur-md max-sm:px-8">
              <div v-if="!$store.state.core.hasActiveSubscription" class="text-white">
                <!-- package section -->
                <div>
                  <p class="text-black font-sans font-bold uppercase py-3 text-lg">
                    Get Membership
                  </p>
                </div>
                <MembershipItemsVue ref="membershipPlans" />
                <div>
                  <p class="text-black text-2xs uppercase text-center font-sans">
                    By creating an account you agree to our
                    <router-link to=""><span class="underline">
                        Terms and Condition</span></router-link>
                  </p>
                </div>
                <div class="mt-8 flex justify-center text-lg text-black">
                  <button @click.prevent="getCheckoutLink"
                    class="rounded-lg bg-red-600 bg-opacity-100 font-sans px-10 py-2 text-white shadow-xl backdrop-blur-md transition-colors btn-lg duration-300 hover:bg-yellow-600 w-full uppercase">
                    Become a fan
                  </button>
                </div>
                <div class="">
                  <div class="mt-2 text-center justify-center pl-4">
                    <p class="text-black flex flex-wrap text-2xs">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="green" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="white" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      <span class="mt-0 font-bold font-sans">
                        No Adult Related Trascation in your bank account</span>
                    </p>
                  </div>
                  <div class="mt-2 pl-4 text-center justify-center">
                    <p class="text-black flex flex-wrap text-center text-2xs">
                      <span class="mt-0 font-sans">
                        You will gain access to all videos and updates available to
                        <br />
                        <span class="font-sans font-bold uppercase">PUSSY DELUXE
                        </span>
                        fanclub members for 1 month <br />
                        Total Charge $1.99</span>
                    </p>
                  </div>
                </div>
                <div>
                  <img src="@/assets/VISA.png" alt="" class="h-16 justify-center mx-auto" />
                </div>


              </div>
              <div v-else class="flex justify-center">
                <div class="block max-w-sm rounded-lg bg-white p-6 shadow-lg dark:bg-neutral-700">
                  <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                    Welcom back <b>{{ $store.state.core.userData.username }}</b>
                  </h5>
                  <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Current active subscription is for {{ activeSubscription.package.subscriptionDuration }} Month.
                    Enjoy Porntool :)
                  </p>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>

  <div class="w-full ">

    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full bg lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      
      <h2 class="max-w-lg  mb-6 font-sans text-4xl font-bold leading-none uppercase tracking-tight text-black sm:text-4xl md:mx-auto">
      
        Videos from the best <span class="text-red-500">studios</span> 
      </h2>
      
    </div>
    <div class="grid max-w-screen-lg gap-8 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-4 grid-cols-2 sm:mx-auto">
      <img class="object-cover w-full h-56 rounded shadow-lg" src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260" alt="" />
      <img class="object-cover w-full h-56 rounded shadow-lg" src="https://images.pexels.com/photos/3182746/pexels-photo-3182746.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260" alt="" />
      <img class="object-cover w-full h-56 rounded shadow-lg" src="https://images.pexels.com/photos/3184311/pexels-photo-3184311.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500" alt="" />
      <img class="object-cover w-full h-56 rounded shadow-lg" src="https://images.pexels.com/photos/3184339/pexels-photo-3184339.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260" alt="" />
    </div>
  
  </div>
  </div> <hr class="border-2">
</template>
<script>
/* eslint-disable */
import LoginComponent from '@/components/medium/LoginComponent.vue'
import {openModal} from "jenesius-vue-modal";
import FanclubComponent from '@/components/small/FanclubComponenet.vue'
import MembershipItemsVue from '@/components//small/MembershipItems.vue';
import { CHECKOUT_STRIPE } from '@/queries/main'
import helperMixin from '@/mixins/helpers';
export default {
  mixins: [helperMixin],
  components: {
    MembershipItemsVue,
    FanclubComponent,
    LoginComponent
  },
  methods: {
    async getCheckoutLink() {
      try {
        if(!this.$store.state.core.loggedIn) {
          const props = {title: "Hello"};
          openModal(LoginComponent, props);
          return
        }
        
        this.showSpinner()
        let { data: { createStripeCheckout } } = await this.$apollo
          .mutate({
            mutation: CHECKOUT_STRIPE,
            variables: {
              input: {
                package: this.$refs.membershipPlans.active,
                discount: null,
                coupon: null,
                currency: 'usd'
              },
            },
          })
        this.hideSpinner()
        console.log(createStripeCheckout)
        window.location.href = createStripeCheckout
      } catch (e) {
        this.hideSpinner()
        console.log(e)
        alert('There was some error')
      }
    }
  },
  computed: {
    activeSubscription: function () {
      let sub = this.$store.state.core.porntoolSubscriptions.find(s => s.expired == false)
      return sub
    }
  }
}
</script>

<style>
input {
  background-image: url("data:image/svg+xml;base64, encoded-svg-code");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  /* adjust padding to accommodate the icon */
}
</style>
