<template>


<div class="px- py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-2 lg:py-20">
<div>

    <div class="max-w-xl mb-10 md:mx-auto sm:text-center text-center lg:max-w-2xl md:mb-12">
    <div>
      <!-- <p class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
        Brand new
      </p> -->
    </div>
    <h2 class="max-w-lg mb-6 font-sans uppercase text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
     
     Direct PURCHASE
    </h2>
    <p class="text-base text-gray-700 md:text-lg">
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque rem aperiam, eaque ipsa quae.
    </p>
  </div>
  
</div>
  <div class="grid max-w-screen-lg gap-2 p-2 row-gap-5 mb-8 sm:grid-cols-2 grid-cols-2 lg:grid-cols-4 sm:mx-auto">
    <div class="relative w-full transition-shadow duration-300 hover:shadow-xl">
      <img class="object-cover w-full h-44 rounded shadow-lg sm:h-64 md:h-80 lg:h-44" src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260" alt="" />
      <a href="/" aria-label="Play Video" class="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25">
        <div class="flex items-center justify-center w-16 h-16 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
          <svg class="w-10 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z"
            ></path>
          </svg>
        </div>
      </a>
    </div>
    <div class="relative w-full transition-shadow duration-300 hover:shadow-xl">
      <img class="object-cover w-full h-44 rounded shadow-lg sm:h-64 md:h-80 lg:h-44" src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260" alt="" />
      <a href="/" aria-label="Play Video" class="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25">
        <div class="flex items-center justify-center w-16 h-16 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
          <svg class="w-10 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z"
            ></path>
          </svg>
        </div>
      </a>
    </div>     <div class="relative w-full transition-shadow duration-300 hover:shadow-xl">
      <img class="object-cover w-full h-44 rounded shadow-lg sm:h-64 md:h-80 lg:h-44" src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260" alt="" />
      <a href="/" aria-label="Play Video" class="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25">
        <div class="flex items-center justify-center w-16 h-16 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
          <svg class="w-10 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z"
            ></path>
          </svg>
        </div>
      </a>
    </div>
    <div class="relative w-full transition-shadow duration-300 hover:shadow-xl">
      <img class="object-cover w-full h-44 rounded shadow-lg sm:h-64 md:h-80 lg:h-44" src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260" alt="" />
      <a href="/" aria-label="Play Video" class="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25">
        <div class="flex items-center justify-center w-16 h-16 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
          <svg class="w-10 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z"
            ></path>
          </svg>
        </div>
      </a>
    </div>  </div>
  
</div>
</template>