<template>
  <div class="container">
    <h1 style="font-size:22px;">Oline models</h1>
    <router-link v-for="model in onlineModels" :key="model.username" style="display:block" :to="'/livecam/'+model.username">
      {{model.username}}
    </router-link>
  </div>
</template>
<script>
import axios from '@/axios'
export default {
  data() {
    return {
      onlineModels :[]
    }
  },
  async created() {
    try{
      let {data} = await axios.get('onlinemodels')
      this.onlineModels = data.onlineModels
      // console.log(data.onlineModels)
    }catch(e) {
      console.log(e)
    }
  }
}
</script>