<template>
	<div class="overflow-auto -mt-48 mb-48 -mr-16 -ml-16 - " style="background-image: url('@/assets/profilesetup/background.jpg');">
		<!-- <nav-bar
			v-if="
				!(($route.name == 'Chat' || $route.name == 'DirectChat') && $mq == 'sm')
			"
		/> -->

		> 
        <div class="relative  pb-72 " >
  <!-- <img src="@/assets/profilesetup/Profile.jpg" class="absolute inset-0 object-cover w-full h-full" alt=""> -->
  <!-- Other content here --><img src="@/assets/profilesetup/Profile.jpg" class="absolute inset-0 object-cover w-full h-full" alt="" />
  <div class="md:mx-0 mt-28 w-full"  >
			<div class="
            relative px-0 py-1 overflow-auto mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-0 bg-pink-50 bg-opacity-100 card
					" style="margin: auto">
				<div class="grid grid-cols-1 lg:grid-cols-1">
					<ul class="w-full steps text-lg font-sans mb-7 mt-3">
						<li 
							class="step dark:text-gray-200 font-sans font-bold text-sm uppercase">
							<img src="@/assets/profilesetup/Cam.png" class="h-10 font" alt="">
							<p class=" text-2xs">Profile Picture</p> 
						</li>
						<li  class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"> <img
								src="@/assets/profilesetup/details.png" class="h-10 font" alt="">
<p class="text-2xs">Basic Information</p>	
						</li>

						<!-- {{
							$store.state.core.user.authSource
						}} -->
						<li
							class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
							> <img src="@/assets/profilesetup/verify.png"
								class="h-10 font" alt="">
								<p class="text-2xs">	Verify</p>
						</li>
					</ul>
				</div>

			</div>

            <div class="
            relative px-0 py-1 -mt-5 pt-5 mb-5 lg:w-full w-72  overflow-auto mx-auto sm:max-w-xs md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-0 bg-pink-100 bg-opacity-0 card border-0
					" style="margin: auto">
                    <!-- component -->
<div class="relative flex min-h-screen -mt-28 flex-col justify-center overflow-hidden bg-gray-0 py-">
  <div class="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
    <div class="mx-auto flex w-full max-w-md flex-col space-y-16">
      <div class="flex flex-col items-center justify-center text-center space-y-2">
        <div class="font-semibold text-3xl">
          <p>Email Verification</p>
        </div>
        <div class="flex flex-row text-sm font-medium text-gray-400">
          <p>We have sent a code to your email ba**@dipainhouse.com</p>
        </div>
      </div>

      <div>
        <form action="" method="post">
          <div class="flex flex-col space-y-16">
            <div class="flex flex-row items-center lg:gap-2  justify-between mx-auto w-full max-w-sm">
              <div class="w-16 h-16 ">
                <input class="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="" id="">
              </div>
              <div class="w-16 h-16 ">
                <input class="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="" id="">
              </div>
              <div class="w-16 h-16 ">
                <input class="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="" id="">
              </div>
              <div class="w-16 h-16 ">
                <input class="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="" id="">
              </div>
            </div>

            <div class="flex flex-col space-y-5">
              <div>
                <button class="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-red-500 border-none text-white text-sm shadow-sm">
                  Verify Account
                </button>
              </div>

              <div class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                <p>Didn't recieve code?</p> <a class="flex flex-row items-center text-blue-600" href="http://" target="_blank" rel="noopener noreferrer">Resend</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

			</div>
<!--             
			<div style="margin: auto" class="
    mx-72 z-50
    grid mt-10
    lg:w-96
    xl:w-96
    md:w-2/3
    sm:w-2/3 bg-pink-100 py-3 px-2
    bg-pink-00 
    dark:bg-gray-900
    rounded-lg
    shadow-xl justify-center
">
    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="">
    <input type="file" class="file-input file-input-bordered file-input-secondary w-full max-w-xs">
</div> -->

			

		</div>


</div> 
        <!-- <img src="@/assets/profilesetup/Profile.jpg" style="height: 600px;" class="absolute z-negative z-0 inset-0 object-cover  w-full h-full" alt="" /> -->
	
		
		
		<div class="spacer mb-10"><footer class=" z-50 bg-white w-full  py-5">

<div class="flex flex-wrap justify-center">
	<p class="font-sans font-bold uppercase mt-3">Submit your Information to get verified</p>
	<div class=" grid lg:grid-cols-2 gap-2 grid-cols-2 mt-10">
	</div>

	<div class=" "><img class=" lg:float-right lg:h-12 h-12"
			:src="require('../assets/icons/PorntoolLogo.png')"
			alt="Connect with flirttool & start your personal online dating adventure! " /></div>
	<div><img class="float-left lg:h-12 h-12 pl-5" :src="require('../assets/logo-light.png')"
			alt="Connect with flirttool & start your personal online dating adventure! " /></div>
</div>
</footer></div>


		




		<!-- <page-footer
			v-if="!($route.name == 'Chat' || $route.name == 'DirectChat')"
		/> -->

		<!-- <div class="mb-5"></div> -->
		<!-- start of basic form  -->
	</div>


</template>





