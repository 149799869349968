<template>
  <!-- ******************* The Navbar Area ******************* -->
  <div id="wrapper-navbar" itemscope itemtype="http://schema.org/WebSite">
    <a class="skip-link sr-only sr-only-focusable" href="#content">Skip to content</a>

    <!-- <h2 class="d-block d-md-none header-title">XVideos Clone WP Template</h2> -->

    <nav class="navbar navbar-expand-md navbar-dark">
      <div class="container">
        <!-- The WordPress Menu goes here -->
        <Transition>
          <div v-if="showTagBar && !smallWidth" class="navbar-collapse show w-full "> 
            <div class="w-full"><ul id="menu-main-menu" class="navbar-nav ml-auto">
              <li v-for="tag in visibleTags" :key="tag.id" class="menu-item nav-item tag-menu">
                <a @click.prevent="toggleTag(tag)" href="#" class="btn btn-xs text-sm font-sans"
                  :class="{'btn-primary':$store.state.core.tags.includes(tag.tag_name),'btn-grey':!$store.state.core.tags.includes(tag.tag_name)}"
                >{{tag.tag_name}}</a>
              </li><button v-if="hasMoreTags" @click="loadMoreTags" class="btn btn-xs text-sm font-sans btn-primary">
              Load More Tags
            </button>
            </ul></div>
            <div> </div>
            
           
          </div>
        </Transition>
      </div>
      <!-- .container -->
    </nav>
    <!-- .site-navigation -->
    <Transition>
      <ul v-if="smallWidth && showTagBar" class="mobile-tag-nav ml-auto">
        <li v-for="tag in tags" :key="tag.id" class="menu-item nav-item tag-menu">
          <a @click.prevent="toggleTag(tag)" href="#" class="btn btn-sm"
            :class="{'btn-primary':$store.state.core.tags.includes(tag.tag_name),'btn-grey':!$store.state.core.tags.includes(tag.tag_name)}"
          >{{tag.tag_name}}</a>
        </li>
      </ul>
    </Transition>

    <div class="clear"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { GET_ALL_TAGS } from '@/queries/main'
import LoginComponent from '@/components/medium/LoginComponent.vue'
import { openModal } from "jenesius-vue-modal";
import helperMixin from '@/mixins/helpers';

export default {
  mixins: [helperMixin],
  apollo: {
    getAllTags: {
      query: GET_ALL_TAGS,
      result({ data: { getAllTags } }) {
        this.tags = getAllTags;
      },
    },
  },
  mounted() {
    const mq = window.matchMedia("(min-width: 750px)");
    if (!mq.matches) {
      this.showTagBar = false;
      this.smallWidth = true;
    }
  },
  data() {
    return {
      tags: [],
      showTagBar: true,
      smallWidth: false,
      limit: 20,
      loadedTags: 15,
    };
  },
  computed: {
    visibleTags() {
      return this.tags.slice(0, this.loadedTags);
    },
    hasMoreTags() {
      return this.loadedTags < this.tags.length;
    },
  },
  methods: {
    logout() {
      this.logOutUser();
      this.$router.push("/");
    },
    login() {
      const props = { title: "Hello" };
      openModal(LoginComponent, props);
    },
    toggleTagBar() {
      this.showTagBar = !this.showTagBar;
    },
    toggleTag(tag) {
      let tags = this.$store.state.core.tags;
      let tagName = tag.tag_name;
      const findIndex = tags.indexOf(tagName);
      if (findIndex > -1) {
        tags.splice(findIndex, 1);
        this.$store.commit("tags", tags);
      } else {
        tags.push(tagName);
        this.$store.commit("tags", tags);
      }
    },
    loadMoreTags() {
      this.loadedTags += 10;
    },
  },
};
</script>

<style>
.image-profile {
  height: 37px;
  width: 43px;

  display: inline-block;
  border-radius: 100%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.mobile-tag-nav {
  padding: 15px;
}

.mobile-tag-nav li {
  float: left;
  font-size: 14px;
  margin: 0 5px 0 0;
  margin-top: 4px;
  list-style-type: none;
  white-space: nowrap;
}
</style>
