<template>
   <NavBar/>
   <AppHeader/>
  <VideoGridComponent />
   <div class="happy-section"><a href="#!"><img src="https://demo-vtt-adult.wp-script.com/wp-content/themes/vtube/img/happy-4.png"></a></div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
 import NavBar from '@/components/NavBar.vue'
import VideoGridComponent from "@/components/medium/VideoGridComponent.vue";
export default {
  components: {
    VideoGridComponent,AppHeader,NavBar
  },
};
</script>
