<template>
  <div
    class="overflow-auto -mt-48 mb-48 -mr-16 -ml-16 -"
    style="background-image: url('@/assets/profilesetup/background.jpg')"
  >
    <div class="relative pb-72">
      <!-- <img src="@/assets/profilesetup/Profile.jpg" class="absolute inset-0 object-cover w-full h-full" alt=""> -->
      <!-- Other content here --><img
        src="@/assets/profilesetup/Profile.jpg"
        class="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div class="md:mx-0 mt-28 w-full">
        <div
          class="relative px-0 py-1 overflow-auto mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-0 bg-pink-50 bg-opacity-100 card"
          style="margin: auto"
        >
          <div class="grid grid-cols-1 lg:grid-cols-1">
            <ul class="w-full steps text-lg font-sans mb-7 mt-3">
              <li
                class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
              >
                <img
                  src="@/assets/profilesetup/Cam.png"
                  class="h-10 font"
                  alt=""
                />
                <p class="text-2xs">Profile Picture</p>
              </li>
              <li
                class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
              >
                <img
                  src="@/assets/profilesetup/details.png"
                  class="h-10 font"
                  alt=""
                />
                <p class="text-2xs">Basic Information</p>
              </li>

              <!-- {{
							$store.state.core.user.authSource
						}} -->
              <li
                class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
              >
                <img
                  src="@/assets/profilesetup/verify.png"
                  class="h-10 font"
                  alt=""
                />
                <p class="text-2xs">Verify</p>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="relative px-0 py-1 mt-5 pt-5 mb-10 m overflow-auto mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-0 bg-pink-100 bg-opacity-0 card border-0"
          style="margin: auto"
        >
          <div
            style="margin: auto"
            class="mx-72 z-50 grid mt-10 lg:w-96 bg-pink-50 xl:w-96 md:w-2/3 sm:w-2/3 py- px-2 bg-pink-00 dark:bg-gray-900 rounded-lg shadow-xl justify-center"
          >
            <img
              :src="profileUrl"
              class="mt-2 mb-2"
              alt=""
            />
            <input
            ref="profile_pic"
            @change="fileAdded"
              type="file"
              class="file-input file-input-bordered file-input-secondary w-full max-w-xs mb-2"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <img src="@/assets/profilesetup/Profile.jpg" style="height: 600px;" class="absolute z-negative z-0 inset-0 object-cover  w-full h-full" alt="" /> -->

    <div class="spacer mb-10">
      <footer class="z-50 bg-white w-full py-5">
        <div class="flex flex-wrap justify-center">
          <p class="font-sans font-bold uppercase mt-3">
            Submit your Information to get verified
          </p>
          <div class="grid lg:grid-cols-2 gap-2 grid-cols-2 mt-10"></div>

          <div class=" ">
            <img
              class="lg:float-right lg:h-12 h-12"
              :src="require('../assets/icons/PorntoolLogo.png')"
              alt="Connect with flirttool & start your personal online dating adventure! "
            />
          </div>
          <div>
            <img
              class="float-left lg:h-12 h-12 pl-5"
              :src="require('../assets/logo-light.png')"
              alt="Connect with flirttool & start your personal online dating adventure! "
            />
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { UPDATE_PROFILE_PIC} from '@/queries/main';
import helperMixin from '@/mixins/helpers'
export default {
  created() {
    let setupStep = this.$store.state.core.userData.profileSetupStep
    if(setupStep == 1) {
      this.$router.push("/detail");
    } else if(setupStep == 2) {
      this.$router.push("/verify");
    }
  },
  mixins:[helperMixin],
  data: () => ({
		profileUrl: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
		fileUploaded: false,
		showError: false,
		loadingUpload: false,
	}),
	methods: {
		getBlobUrl(file) {
			var fileReader = new FileReader();
			const url = fileReader.readAsDataURL(file);
			fileReader.onload = (e) => {
				this.profileUrl = e.target.result
			};
			return url;
		},
		fileAdded() {
			this.showError = false;
			this.fileUploaded = true
			const profile_pic = this.$refs.profile_pic.files[0];
			this.getBlobUrl(profile_pic)
			this.uploadPic(profile_pic)
		},
		uploadPic(file) {
			console.log(file)
			this.loadingUpload = true
      this.showSpinner()
      this.$apollo.provider.clients.uploadClient
				.mutate({
					mutation: UPDATE_PROFILE_PIC,
					variables: { file: file },
      	})
				.then(({ data }) => {
					if (data) {
						// update state profile pic and step
						const newUrl = data.updateProfilePic;
						let user = this.$store.state.core.userData;
						user.profilePic = newUrl;
						user.profileSetupStep = 1;
						console.clear()
						console.warn(newUrl,"new URL")
						this.$store.commit("userData", user)
						this.fileUploaded = true
					}
				}).finally(() => {
          this.hideSpinner()
					this.loadingUpload = false
				}).then(()=>{
          this.$router.push("/detail");
				});
		},
	},
}
</script>
