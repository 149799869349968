<template>
  <div class=" col-6 col-md-4 col-lg-3 col-xl-3">
    <div
      class="video-block video-with-trailer bg-gray-100"
      data-post-id="14751"
      >
      <router-link @click="scroll" :to="'/video/'+video.id" class="thumb" >
        <div class="video-debounce-bar" :class="{'video-debounce-bar--wait':videoPreview}"></div>
        <div v-if="!imageLoaded" class="lds-dual-ring"></div>
        <img 
          v-on:mouseover="showPreview('in')"
          v-on:mouseleave="showPreview('leave')"
          @load="onImageLoad"
          class="video-img img-fluid loaded " 
          :class="{'video-img--hidden contain-fit':videoPreview}"
          :src="getUrl(video.thumbnail)"
          style="object-fit: contain;"
         >
        <div class="video-preview">
          <video v-if="videoPreview" style="height:100%;width:100%" autoplay="" loop="" muted="" preload="none">
            <source :src="getPreviewVideo" type="video/mp4">
          </video>
        </div>
      </router-link>
      <div class="bg-gray-100 py-2 h-16 justify-center lg:text-md text-left px-3  font-md uppercase"><a class="" href="https://demo-vtt-adult.wp-script.com/cheating-housewife-desperate-for-pleasure/" title="Cheating housewife desperate for pleasure">
      <span class="title">{{video.title}}</span>
      </a>
      <div class="video-datas ">
        <!-- <span class="views-number">10848 views</span> -->
        - <span class="duration">{{duration}}</span>
      </div></div>
      
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import helperMixin from '@/mixins/helpers' 
export default { 
  mixins:[helperMixin],
  props:['video'],
  data() {
    return {
      previewTimeout: null,
      videoPreview: false,
      imageLoaded: false
    }
  },
  methods: {
    onImageLoad() {
      this.imageLoaded = true
    },
    scroll() {
      window.scrollTo(0,0,{behavior: 'smooth'})
    },
    showPreview(data) {
      if(data == 'in') {
         this.previewTimeout = setTimeout(() => {
           if(this.previewTimeout) {
             this.videoPreview = true
             console.log("INITATING PREVIEW OUT FOR",this.video.duration)
           }
        },1000)
      }
      if(data == 'leave') {
        this.videoPreview = false
        clearTimeout(this.previewTimeout)
      }
    },
  },
  computed: {
    duration:function() {
      let time = this.video.duration
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time - minutes * 60);
      return `${minutes}:${seconds}`
    },
    getPreviewVideo:function() {
      return this.video.preview_url ?  this.getUrl(this.video.preview_url)
      : 'https://dw.rdtcdn.com/media/videos/201203/01/148364/360P_360K_148364.mp4' 
    }
  }
}
// on hover add class --> video-debounce-bar--wait 
// add class --> video-img--hidden
</script>
