<template>
  <div class="wrapper" id="single-wrapper">
       <NavBar/><AppHeader/>
  <article id="post-14751" class="post-14751 post type-post status-publish format-video has-post-thumbnail hentry category-creampie tag-cheating tag-housewife post_format-post-format-video actors-pleasure" itemprop="video" itemscope itemtype="http://schema.org/VideoObject">
    <section class="single-video-player">
      <div class="container">
        <div class="video-title">
          <h1>{{media.title}}</h1>
        </div>
        <div class="video-cat-tag">
          <a class="btn btn-black" href="https://demo-vtt-adult.wp-script.com/category/creampie/" title="Creampie">Creampie <span>69</span>
          </a>
          <a v-for="tag in media.tags" :key="tag.id" class="btn btn-grey btn-xs text-sm font-sans" href="#" title="pleasure">{{tag.name}}</a>
        </div>
        <div class="d-flex justify-content-between">
          <div class="video-left">
            <div class="video-wrapper">
              <div class="responsive-player video-player">
                <!-- <video width="400" controls>
                  <source src="https://flirttool.com/rest/stream/18?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE4NTgyLCJpYXQiOjE2NzgwMDY4MDR9._2CuCKus2BSVUrdgwxutYy_LvWDNV3kOYAFmg8cYXwg" type="video/mp4">
                  Your browser does not support HTML video.
                </video> -->
               <video-player
               v-if="mediaLoaded"
                  class="video-player vjs-big-play-centered"
                  :src="getUrl(media.url)"
                  :sources="[{
                    src: getSourceLink,
                    type: 'video/mp4'
                  }]"
                  :poster="getUrl(media.thumbnail)"
                  crossorigin="anonymous"
                  playsinline
                  controls
                  ref="player"
                  :volume="0.6"
                  :height="320"
                  :autoplay="true"
                  :playback-rates="[0.7, 1.0, 1.5, 2.0]"
                  @mounted="handleMounted"
                  @ready="handleEvent($event)"
                  @play="handleEvent($event)"
                  @pause="handleEvent($event)"
                  @ended="handleEvent($event)"
                  @loadeddata="handleEvent($event)"
                  @waiting="handleEvent($event)"
                  @playing="handleEvent($event)"
                  @canplay="handleEvent($event)"
                  @canplaythrough="handleEvent($event)"
                  
                />
              </div>
            </div>
            <div class="happy-under-player">
              <router-link :to="`/membership?model=${media.gallery?.owner?.username}`">
                <img src="https://demo-vtt-adult.wp-script.com/wp-content/themes/vtube/img/happy-3.png">
              </router-link>
            </div>
            <div class="videos-infos d-flex align-items-center">
              <div id="video-views">
                <span class="views-number"></span> views
              </div>
              <div id="rating">
                <span id="video-rate">
                  <span class="post-like">
                    <a class="btn btn-grey" href="#" data-post_id="14751" data-post_like="like">
                      <span class="like" title="I like this"></span>
                        <span id="more">
                          <i class="fa fa-thumbs-up"></i>
                        </span>
                        <span class="likes_count">0</span>
                    </a>
                    <a class="btn btn-grey" href="#" data-post_id="14751" data-post_like="dislike">
                      <span title="I dislike this" class="qtip dislike">
                        <span id="less">
                          <i class="fa fa-thumbs-down fa-flip-horizontal"></i>
                        </span>
                      </span>
                      <span class="dislikes_count">0</span>
                    </a>
                  </span>
                </span>
              </div>
              <div class="video-actions-header">
                <button class="tab-link comment btn-grey-light" data-tab-id="video-comment">
                  <i class="fa fa-comment"></i>
                  <span class="d-none d-md-inline-block">Comment</span>
                </button>
                <button class="tab-link share btn-grey-light" data-tab-id="video-share">
                  <i class="fa fa-share-alt"></i>
                  <span class="d-none d-md-inline-block">Share</span>
                </button>
              </div>
            </div>
          </div>
          <div class="video-sidebar d-none d-md-block">
            <div class="happy-player-beside">
              <div class="zone-1">
                <a href="#!">
                  <img src="https://demo-vtt-adult.wp-script.com/wp-content/themes/vtube/img/happy-2.png">
                </a>
              </div>
              <div class="zone-2">
                <a href="#!">
                  <img src="https://demo-vtt-adult.wp-script.com/wp-content/themes/vtube/img/happy-2.png">
                </a>
              </div>
            </div>
          </div>
        </div>
        <VideoGridComponent
        v-if="mediaLoaded"
        :inputTags="videoTags"
        />
      </div>
    </section>
  </article>
  <div class="happy-section">
    <a href="#!">
      <img src="https://demo-vtt-adult.wp-script.com/wp-content/themes/vtube/img/happy-4.png">
    </a>
  </div>
</div>
</template>

<script>
//https://flirttool.com/rest/porntoolstream/112?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE4NTgyLCJpYXQiOjE2Nzg4NzM5Nzd9.BSugiaGgtuWM9eL0yWiZtVS42rToUAOM8dE3TRPPCk4
  
/* eslint-disable */
import { GET_MEDIA_INFO } from '@/queries/main'
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import helperMixin from '@/mixins/helpers'
import MarqueeComponent from '@/components/small/MarqueeComponent.vue'
import VideoGridComponent from "@/components/medium/VideoGridComponent.vue";
import AppHeader from '@/components/AppHeader.vue'
 import NavBar from '@/components/NavBar.vue'
export default {
  mixins:[helperMixin],
  async mounted() {
   this.getVideoData()
  },
  async beforeRouteUpdate(to, from, next) {
    this.media = {}
    this.videoTags = []
    this.mediaLoaded = false
    await this.getVideoData(to.params.id)
    next()
  },
  // apollo: {
  //   getMediaInfo: {
  //     query: GET_MEDIA_INFO,
  //     variables() {
  //       return {
  //          media: Number(this.$route.params.id)
  //       };
  //     },
  //     result({data :{getMediaInfo}}) {
  //       this.hideSpinner()
  //       this.media = getMediaInfo 
  //       this.videoTags = this.media.tags.map(t => t.name)
  //       this.mediaLoaded = true
  //       console.log(getMediaInfo)
  //     },
  //   },
  // },
  components: {
    VideoPlayer,
    MarqueeComponent,
    VideoGridComponent,AppHeader,NavBar
  },
  data() {
    return {
      media:{},
      videoTags:[],
      mediaLoaded:false
    }
  },
  methods:{
   async getVideoData(id = null) {
     let videoId = id ? Number(id) : Number(this.$route.params.id) 
      this.showSpinner()
      let {data} = await this.$apollo.mutate({
        mutation: GET_MEDIA_INFO,
        variables: {
            media: videoId
        },
      })
      if(data.getMediaInfo) {
        this.hideSpinner()
        this.media = data.getMediaInfo 
        this.videoTags = this.media.tags.map(t => t.name)
        this.mediaLoaded = true
      }
    },
    handleMounted(payload) {
       const player = this.$refs.player
      player.value = payload.player
      // console.log('Basic player mounted', payload)
    },
    handleEvent(log) {
      // console.log('Basic player event', log)
    }
  },
  computed: {
    getSourceLink:function() {
      if(this.$store.state.core.hasActiveSubscription) {
        return `https://flirttool.com/rest/porntoolstream/${this.media.id}?token=${this.$store.state.core.token}`
      }else{
        return this.media.preview_url ?  this.getUrl(this.media.preview_url)
         : 'https://dw.rdtcdn.com/media/videos/201203/01/148364/360P_360K_148364.mp4' 
      }
    },
  }
}
</script>
<style>
 .vjs-play-progress:before {
    top: 0em !important;  
  }
 </style>
