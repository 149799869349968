

<template>
    <div class=" lg:-ml-16 lg:-mr-16  md:-mt-14 ">
        <!-- navi -->
        <div class=" bg-red-900">
            <div class="bg-black">
                <div class="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">


                    <LandingTopnav />
                    <!-- <div class="relative flex grid items-center grid-cols-2 lg:grid-cols-3">
                        <ul class="flex items-center  space-x-8 lg:flex">
                            
                        </ul>
                        <a href="/" aria-label="Company" title="Company" class="inline-flex items-center lg:mx-auto">

                            <img src="@/assets/images/Porntool.png" class="h-12 rounded-lg" alt="">
                        </a>
                        <ul class="flex items-center  ml-auto space-x-8 lg:flex">
                            <li><a href="/" aria-label="Sign in" title="Sign in"
                                    class="font-medium border-2  py-1 px-3 tracking-wide text-gray-100 duration-200  shadow-2xl  text-lg font-sans uppercase rounded-lg">login</a>
                            </li>
                            <li>
                                <a href="/"
                                    class="font-medium border-0  py-1 px-3 tracking-wide text-gray-100 bg-red-500 duration-200  shadow-2xl  text-lg font-sans uppercase rounded-lg"
                                    aria-label="Sign up" title="Sign up">
                                    Sign up
                                </a>
                            </li>
                       
                    </div> -->
                </div>
            </div>
            <div class=" bg-gray-600">
                <ul
                    class=" flex flex-wrap py-3 justify-center  text-white font-bold font-sans text-lg uppercase lg:gap-16 gap-5">
                    <li>home</li>
                    <li> <a href="#video-category-section"> Video Catergory</a> </li>
                    <li> <a href="#hot-models"> Hot Models</a></li>
                    <li> <a href="#latest-videos"> New videos</a></li>
                    <li>Vip Models</li>
                    <li> <a href="#live-cam"> Live Cam Videos
                    </a></li>
                <li>Previews</li>
                </ul>
            </div>
        </div>

        <!-- navi -->
        <!-- nav menu list -->


        <div class="mb-36">
            <ImgSlider />
        </div>




        <div class="px-4 py-5 mt-16  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <form method="get" id="searchform" action="https://porntool.live/" class=" flex  justify-center">
                <div class="border-2 px-3 py-3    rounded-full gap-5"> <input class="input-group-field w-96"
                        value="Search For Videos..." name="s" id="s"
                        onfocus="if (this.value == 'Search...') {this.value = '';}"
                        onblur="if (this.value == '') {this.value = 'Search...';}" type="text" />
                    <button class="fa-input" type="submit" id="searchsubmit" value="&#xf002;"><i
                            class="fa fa-search"></i></button>
                </div>
            </form>
        </div>

        <!-- video qualty setting  -->


        <div class="px-4 py-0 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">

            <div class="flex flex-wrap justify-center gap-3">

                <div> <span class=" uppercase text-xl items-center justify-center flex mt-3  font-sans font-bold">Video
                        Quality </span> </div>
                <ul class="menu menu-vertical lg:menu-horizontal bg-white border-2 ">

                    <li class="bg-gray-50"><a>HD <span class="text-red-500 font-bold">+</span></a></li>

                </ul>

            </div>


        </div>


        <!-- video quality filter -->
        <!-- end of nav menu list -->
        <div class="px-0 py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-2">

            <div class="lg:col-span-12">
                <!-- Main content goes here -->
                <div class="h-full ml-0 mt-0 mb-10 md:ml-0 " id="latest-videos">


                    <div
                        class="grid grid-cols-1 bg-gray-50 rounded-xl md:grid-cols-1 xl:grid-cols-1 p-4 gap-4 text-black dark:text-black">
                        <div class="md:col-span-2 xl:col-span-1 ">
                            <h3 class="text-4xl font-semibold py-2 text-left px-0 text-black  uppercase font-sans">Latest
                                Videos :</h3>
                        </div>
                        <!-- <LandingNavBar /> -->
                        <div class="flex flex-wrap">


                            <ul>
                                <li
                                    class="text-lg font-sans uppercase border-2 px-2 border-red-500 rounded-xl text-red-500  font-bold mb-3 shadow-lg">
                                    <button class="uppercase font-sans">Recent Uploads</button>
                                </li>
                            </ul>
                        </div>
                        <div class=" flex  gap-2">
                            <div class="grid gap-3 w-full sm:grid-cols- lg:grid-cols-4">

                                <!-- <LandingVideoComponent v-for="video in mostViewed" :key="video.id" :video="video" /> -->
                                <LandingVideoComponent v-for="video in filteredMostViewed" :key="video.id" :video="video" />

                            </div>

                            <!-- <div>
                                <img src="@/assets/images/updateimages/1.jpg"
                                    style="height: 456px; width: 300px;  object-fit: cover;">
                            </div> -->

                        </div>

                    </div>


                    <!-- banner -->


                </div>
                <div class=" flex justify-center py-5">
                    <button v-if="showLoadMore" @click="loadMoreVideos"
                        class=" bg-red-500 font-sans px-2 py-2 uppercase font-bold text-white rounded-xl shadow-xl">Load
                        More</button>
                </div>
                <!-- here -->
                <div>

                    <!-- next section -->
                    <div><img src="@/assets/images/slider/Banner1.png" alt=""></div>
                </div>
                <!-- banner -->
                <!-- live cam preview -->
                <div class="md:col-span-2 xl:col-span-1 " id="live-cam">
                    <h3
                        class="text-2xl  flex flex-wrap gap-5 font-semibold py-5 text-left px-0 text-black  uppercase font-sans">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="white" class="w-9 h-9">
                            <path stroke-linecap="round"
                                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                        </svg>

                        <span>Recent Live Cam Preview</span>
                    </h3>
                </div>
                <div class=" flex  gap-2">
                    <div class="grid gap-3 w-full sm:grid-cols- lg:grid-cols-4">

                        <!-- <LandingVideoComponent v-for="video in mostViewed" :key="video.id" :video="video" /> -->
                        <LandingVideoComponent v-for="video in filteredMostViewed" :key="video.id" :video="video" />

                    </div>



                </div>



                <!-- Live Cam Preview -->

                <div class="grid grid-cols-1 md:grid-cols-1 mt-3 xl:grid-cols-1 p-4 gap-4 text-black dark:text-black bg-gray-50" 
                    id="hot-models">
                    <div class="md:col-span-2 xl:col-span-1 ">
                        <h3
                            class="text-2xl  flex flex-wrap gap-5 font-semibold py-5 text-left px-0 text-black  uppercase font-sans">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-9 h-9">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                            </svg>
                            <span>PornTool Models</span>
                        </h3>
                    </div>
                    <!-- new componets -->
                    <div class="px-2 py-2  sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-2 lg:py-2">
                        <div class="grid gap-2 lg:grid-cols-4 sm:max-w-sm sm:mx-auto lg:max-w-full">
                            <div class="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm"
                                v-for="model in models" :key="model.username"
                                :href='"https://flirttool.com/ft/@" + model.username'>
                                <a :href='"https://flirttool.com/ft/@" + model.username'>
                                <img :src="getUrlMedia(model.profilePic)" class="object-cover w-full h-64" alt="" />
                                <div class="p-2 border border-t-0">
                                    <p class="mb-3 text-lg font-sans text-center font-semibold tracking-wide uppercase">
                                        {{ model.username }}
                                    </p>


                                </div></a>
                            </div>

                        </div>
                    </div>
                    <!-- end of new component -->



                </div>

                <div class="grid grid-cols-1 mt-3 md:grid-cols-1 xl:grid-cols-1 p-4 gap-4 text-black dark:text-black bg-gray-0"
                    id="video-category-section">
                    <div class="md:col-span-2 xl:col-span-1 ">
                        <h3
                            class="text-2xl  flex flex-wrap gap-5 font-semibold py-2 text-left px-0 text-black  uppercase font-sans">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="white" class="w-9 h-9">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg> <span>Watch Full Lenght Video :</span>
                        </h3>
                    </div>
                    <LandingNavBar />
                    <VideoGridComponent />
                </div>


            </div>
        </div>

        <!-- mobile -->

    </div>
</template>
  
<script>
import LoginComponent from '@/components/medium/LoginComponent.vue'
// import NavBar from '@/components/NavBar.vue'
import { openModal } from "jenesius-vue-modal";
import { GET_ALL_MODELS_VIDEOS, GET_MODEL_TYPES_COUNT } from "@/queries/main";
import LandingVideoComponent from '@/components/small/LandingVideoComponent.vue'
import helperMixin from "@/mixins/helpers";
import ImgSlider from "@/components/small/ImgSlider.vue";
import LandingNavBar from '@/components/small/LandingNav.vue'
import VideoGridComponent from "@/components/medium/VideoGridComponent.vue";
import LandingTopnav from '@/components/small/LandingTopnav.vue';


export default {
    components: {
        LandingVideoComponent, LandingNavBar, VideoGridComponent, ImgSlider, LandingTopnav
    },
    mixins: [helperMixin],
    data() {
        return {
            isSmallScreen: false,
            newVideos: [],
            mostViewed: [],
            modelTypes: {
                bisexual: 0,
                gay: 0,
                lesbain: 0,
                straight: 0,
                trans: 0,
                total: 0,
            },
            models: [],
            maxVideosToShow: 8, // Set the maximum number of videos to display
            loadMoreCount: 8
        };
    },
    mounted() {
        window.addEventListener("resize", this.checkScreenSize);
        this.checkScreenSize();
        this.getNewModelVideos();
        this.getmostViewedVideos();
        this.getModelTypesCount();
    },
    methods: {
        login() {
            const props = { title: "Hello" };
            openModal(LoginComponent, props);
        },
        logout() {
            this.logOutUser();
            this.$router.push("/");
        },
        async getModelTypesCount() {
            try {
                this.showSpinner();
                const { data: { getModelTypeCount } } = await this.$apollo.query({
                    query: GET_MODEL_TYPES_COUNT,
                });
                this.hideSpinner();
                this.modelTypes.bisexual = getModelTypeCount.bisexual;
                this.modelTypes.gay = getModelTypeCount.gay;
                this.modelTypes.lesbain = getModelTypeCount.lesbain;
                this.modelTypes.straight = getModelTypeCount.straight;
                this.modelTypes.trans = getModelTypeCount.trans;
                this.modelTypes.total = getModelTypeCount.total;
                this.models = getModelTypeCount.models;
            } catch (e) {
                this.hideSpinner();
                console.log(e);
            }
        },
        async getNewModelVideos() {
            try {
                this.showSpinner();
                const { data: { getAllModelVideos } } = await this.$apollo.query({
                    query: GET_ALL_MODELS_VIDEOS,
                    variables: {
                        page: 1,
                        itemsPerPage: 50,
                        isnew: true
                    },
                });
                this.hideSpinner();
                this.newVideos = getAllModelVideos.media;
            } catch (e) {
                this.hideSpinner();
                console.log(e);
            }
        },
        async getmostViewedVideos() {
            try {
                this.showSpinner();
                const { data: { getAllModelVideos } } = await this.$apollo.query({
                    query: GET_ALL_MODELS_VIDEOS,
                    variables: {
                        page: 1,
                        itemsPerPage: 50,
                        mostViewed: true
                    },
                });
                this.hideSpinner();
                this.mostViewed = getAllModelVideos.media;
            } catch (e) {
                this.hideSpinner();
                console.log(e);
            }
        },
        checkScreenSize() {
            this.isSmallScreen = window.innerWidth < 768;
            if (this.isSmallScreen) {
                this.$router.push("/moblanding");
            } else {
                // You can redirect to a different page for larger screens here
            }
        },
        loadMoreVideos() {
            this.maxVideosToShow += this.loadMoreCount;
        }
    },
    computed: {
        filteredMostViewed() {
            // Apply the filter to limit the number of videos displayed
            return this.mostViewed.slice(0, this.maxVideosToShow);
        },
        visibleMostViewed() {
            return this.mostViewed.slice(0, this.maxVideosToShow + this.loadMoreCount);
        },
        showLoadMore() {
            return this.mostViewed.length > this.maxVideosToShow;
        }
    }
};

</script>
<style>
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    height: 500px;
}</style>

