<template>
  <div class="marquee" v-if="$store.state.core.userData.profileSetupStep == 3">
      <marquee scrollamount=8 v-if="$store.state.core.loggedIn && !$store.state.core.hasActiveSubscription" direction="left" >
        Hi, <b>{{$store.state.core.userData.username}}</b> You have no active subscription plan. Please subscribe for a membership 
        <router-link to="/membership" ><b>click here</b></router-link>
      </marquee>
      <marquee v-if="!$store.state.core.loggedIn" scrollamount=8  direction="left" >
        Please Login/Subscribe to Watch the full video
      </marquee>
    </div>
    <div v-else class="marquee">
       <marquee scrollamount=8 v-if="$store.state.core.loggedIn && !$store.state.core.hasActiveSubscription" direction="left" >
        Hi, <b>{{$store.state.core.userData.username}}</b> Your Profile is incomplete Please 
        <router-link to="/avatar" ><b>click here</b></router-link> to complete your profile
      </marquee>
    </div>
</template>

<style>
.marquee {
  font-size:15px;
}
.marquee b {
  color: red
}
</style>