<template>
   <div class="conatiner">
    <div class="lg:grid-cols-12 grid-cols-1  flex px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div class="lg:col-span-6">
        <h1>LiveCam of {{ $route.params.username }}</h1>
         <video ref="myVideo"></video>
      </div>
      <div class="lg:col-span-6" style="margin-left:4rem">
        <h1>Messages</h1>
        <div v-for="(message,index) in messages" :key="index" class="message" :class="{ f_right: message.username == $store.state.core.userData.username }">
          <img :src="`https://flirttool.com/media/${message.profilePic}`">
          <p>{{message.message}}</p>
        </div>
      </div>
    </div>
     <div class="lg:grid-cols-12   flex px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
       <div class="lg:col-span-12" style="margin-left:4rem">
        <h1>send message</h1>
        <textarea class="textarea" v-model="message"></textarea>
        <br>
        <button @click.prevent="sendMessage" class="inline-flex items-center bg-red-500 font-sans justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:bg-red-400 hover:text-red-500">Send message</button>
      </div>
      </div>
  </div>
</template>

<script>
/* eslint-disable */
import env from '@/env'
import axios from '@/axios'
import helperMixin from '@/mixins/helpers'

const PRODUCTION = false
// const socket = window.io("http://localhost:5000/")
const user = 'consumer'
// import  Peer  from 'peerjs-client'
export default {
  mixins:[helperMixin],
   
  data() {
    return {
      modelPeerId: null,
      peer:null,
      message:"",
      conn:null,
      messages:[]
    }
  },
  mounted () {
    let modelRoom = this.$route.params.username;
    var peer = new Peer({
      host: PRODUCTION ? 'cam.porntool.live' : 'localhost', 
     ...(!PRODUCTION) && {port: 5000},
      ...(PRODUCTION) && {port: 443},
      path: '/peerjs',
      config: {
        'iceServers': [
          {
            urls: "stun:a.relay.metered.ca:80",
          },
          {
            urls: "turn:a.relay.metered.ca:80",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
          {
            urls: "turn:a.relay.metered.ca:80?transport=tcp",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
          {
            urls: "turn:a.relay.metered.ca:443",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
          {
            urls: "turn:a.relay.metered.ca:443?transport=tcp",
            username: "b713400626ccb30ffe25c95a",
            credential: "Plt4+JLao80tCGm8",
          },
        ]
      },

      debug: 3
    });
    peer.on("open", async (id) => {
      console.log('my id is' + id);
      await this.updatePeerMeta(id)
      await this.getModelInfo()
      this.connectToModel()
    });
    peer.on("close", (id) => {
      // alert("peer closed")
    });
    peer.on("disconnected", (id) => {
      // alert("peer disconnected")
    });
    peer.on("error", (id) => {
      // alert("peer error")
    });
     const addVideoStream = (video, stream) => {
       video.muted = "muted"
       video.controls = true
      video.srcObject = stream;
      video.addEventListener("loadedmetadata", () => {
        video.play();
      });
    };

    peer.on("call", (call) => {
      console.log('someone call me');
      call.answer(null);
      const video = this.$refs.myVideo
      call.on("stream", (userVideoStream) => {
        addVideoStream(video, userVideoStream);
      });
    });
    this.peer = peer
  },
  methods: {
    async updatePeerMeta(peerId) {
      try{
        let {data} = await axios.post('identify',{
          peerId:peerId
       })
      }catch(e) {
        console.log(e)
      }
    },
    async getModelInfo() {
      try{
        let {data} = await axios.post('modelpeerinfo',{
          username:this.$route.params.username
       })
       this.modelPeerId = data.modelInfo.peerId
       console.log(this.modelPeerId)
       console.log(data)
      }catch(e) {
        console.log(e)
      }
    },
    sendMessage() {
      this.conn.send({
        username:this.$store.state.core.userData.username,
        profilePic:this.$store.state.core.userData.profilePic,
        message:this.message
      })
      this.message = ''
    },
    connectToModel() {
      let toConnectPeer = this.modelPeerId
      console.log(toConnectPeer) 
      var conn = this.peer.connect(toConnectPeer,{
        metadata:{
          username:this.$store.state.core.userData.username
        }
      });
      this.conn = conn
      conn.on('close',()=>{
          console.log("MODEL CONNECTION CLODE RETRYING")
          this.showSpinner()
          setTimeout(async()=>{
            await this.getModelInfo()
            await this.connectToModel()
            this.hideSpinner()
          },7000)
      })
      conn.on('open',()=>{
        
      })
      conn.on('data', (data) => {
        this.messages.push(data)
      });
    }
  },
  
}

</script>
<style scoped>
textarea {
    height: 86px;
  width: 500px;
  border: solid 2px gray;
}
.message {
  margin-top:5px
}
.message img {
  height:30px;
  display:inline-block
}
.message p {
  display:inline-block;
  margin-left:5px;
}
.f_right {
  margin-left:350px
}
</style>
