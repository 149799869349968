<template>
  <div
    class="overflow-auto -mt-48 mb-48 lg:-mr-16 lg:-ml-16 -"
    style="background-image: url('@/assets/profilesetup/background.jpg')"
  >
    <div class="relative pb-16">
      <!-- <img src="@/assets/profilesetup/Profile.jpg" class="absolute inset-0 object-cover w-full h-full" alt=""> -->
      <!-- Other content here --><img
        src="@/assets/profilesetup/Profile.jpg"
        class="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div class="md:mx-0 mt-28 w-full">
        <div
          class="relative px-0 py-1 overflow-auto mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-0 bg-pink-100 bg-opacity-100 card"
          style="margin: auto"
        >
          <div class="grid grid-cols-1 lg:grid-cols-1">
            <ul class="w-full steps text-lg font-sans mb-7 mt-3">
              <li
                class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
              >
                <img
                  src="@/assets/profilesetup/Cam.png"
                  class="h-10 font"
                  alt=""
                />
                <p class="text-2xs">Profile Picture</p>
              </li>
              <li
                class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
              >
                <img
                  src="@/assets/profilesetup/details.png"
                  class="h-10 font"
                  alt=""
                />
                <p class="text-2xs">Basic Information</p>
              </li>

              <!-- {{
							$store.state.core.user.authSource
						}} -->
              <li
                class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
              >
                <img
                  src="@/assets/profilesetup/verify.png"
                  class="h-10 font"
                  alt=""
                />
                <p class="text-2xs">Verify</p>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="relative px-0 py-1 mt-5 mb-1 lg:w-2/6 bg-white border-0 overflow-auto mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-0 bg-pink-100 bg-opacity-0 card"
          style="margin: auto"
        >
          <div
            class="card border-0 shadow-sm bg-accent mx-3 text-accent-content -mt-2"
          >
            <div class="grid md:grid-cols-2 grid-cols-2 gap-5 md:gap-8 mt-1">
              <div class="grid grid-cols-1 mt-5 md:mr-0">
                <label
                  class="text-left mb-1 uppercase md:text-sm text-xs text-gray-900 font-semibold font-sans dark:text-gray-200"
                  >Your Height</label
                >

                <input
                v-model="height"
                  class="py-4 input-lg px-3 ml-2 dark:bg-gray-700 rounded-lg border-2 border-pink-300 mt-1 focus:outline-none dark:text-gray-200 focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                  placeholder="Your Height"
                />
              </div>
              <div class="grid grid-cols-1 mt-5 md:ml-0">
                <label
                  class="text-left mb-1 uppercase md:text-sm dark:text-gray-200 text-xs text-gray-500 font-semibold font-sans"
                  >Hair Color</label
                >

                <select
                v-model="hair_color"
                  class="select-lg select select-bordered select-secondary w-full border-2 border-pink-300 dark:bg-gray-700 dark:text-gray-200"
                >
                  <option disabled="disabled" selected="selected">
                  What's your Hair Color
                </option>
                <option
                  v-for="(type, idx) in hairTypes"
                  :key="idx"
                  v-text="type"
                ></option>
                </select>
              </div>
            </div>
            <div
              class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-3 w-full mt-5 gap-5 text-left"
            >
              <div>
                <label
                  class="text-left mb-1 uppercase md:text-sm dark:text-gray-200 text-xs text-gray-500 font-semibold font-sans"
                  >Birth Year</label
                >

                <select
                  v-model="byear"
                  class="select-lg select select-bordered select-secondary w-full border-2 border-pink-300 dark:bg-gray-700 dark:text-gray-200"
                >
                  <option disabled="disabled" selected="selected">Date</option>
                <option
                  v-for="(type, idx) in validYears()"
                  :key="idx"
                  v-text="type"
                ></option>
                </select>
              </div>

              <div>
                <label
                  class="text-left mb-1 uppercase md:text-sm dark:text-gray-200 text-xs text-gray-500 font-semibold font-sans"
                  >Month</label
                >
                <select
                v-model="bmonth"
                  class="select-lg select select-bordered select-secondary w-full border-2 border-pink-300 dark:bg-gray-700 dark:text-gray-200"
                >
                  <option disabled="disabled" selected="selected">Date</option>
                <option
                  v-for="(type, idx) in months"
                  :key="idx"
                  v-text="type"
                ></option>
                </select>
              </div>
              <div>
                <label
                  class="text-left mb-1 uppercase md:text-sm dark:text-gray-200 text-xs text-gray-500 font-semibold font-sans"
                  >Day</label
                >
                <select
                v-model="bday"
                  class="select-lg select select-bordered select-secondary w-full border-2 border-pink-300 dark:bg-gray-700 dark:text-gray-200"
                >
                  <option disabled="disabled" selected="selected">Date</option>
                <option
                  v-for="(type, idx) in range(1, 32)"
                  :key="idx"
                  v-text="type"
                ></option>
                </select>
              </div>
            </div>

            <div
              class="grid grid-cols-1 md:grid-cols-2 grid-cols-2 gap-5 md:gap-8 mt-1"
            >
              <!-- <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mt-5 gap-11 md:ml-0 mx-3"> -->
              <div class="grid grid-cols-1 mt-5 md:mr-0">
                <label
                  class="text-left mb-1 uppercase md:text-sm dark:text-gray-200 text-xs text-gray-500 font-semibold font-sans"
                  >Gender</label
                >

                <select
                v-model="gender"
                  class="select-lg select select-bordered select-secondary w-full border-2 border-pink-300 dark:bg-gray-700 dark:text-gray-200"
                >
                  <option disabled="disabled" selected="selected">
                  What's your gender
                </option>
                <option
                  v-for="(type, idx) in genders"
                  :key="idx"
                  v-text="type"
                ></option>
                </select>
              </div>
              <div class="grid grid-cols-1 mt-5 md:mr-0">
                <label
                  class="text-left mb-1 uppercase md:text-sm dark:text-gray-200 text-xs text-gray-500 font-semibold font-sans"
                  >Sexual Preference</label
                >

                <select
                 v-model="preference"
                  class="select-lg select select-bordered select-secondary w-full border-2 border-pink-300 dark:bg-gray-700 dark:text-gray-200"
                >
                 <option disabled="disabled" selected="selected">
                  What's your sexual prefrence
                </option>
                <option
                  v-for="(type, idx) in preferences"
                  :key="idx"
                  v-text="type"
                ></option>
                </select>
              </div>
            </div>

            <div class="grid grid-cols-2 md:grid-cols-2 gap-5 md:gap-8 mt-5">
              <div class="grid grid-cols-1">
                <label
                  class="mb-1 uppercase text-left md:text-sm text-xs text-gray-500 font-semibold dark:text-gray-200 font-sans"
                  >Your Country</label
                >
                <select
                  class="select-lg select select-bordered select-secondary w-full border-2 border-pink-300 dark:bg-gray-700 dark:text-gray-200"
                  v-model="country"
                >
                  <option disabled="disabled" selected="selected">
                  Choose Your Country
                </option>
                <option v-for="(c,i) in counteries2" :key="i" :value="c">{{ c.country }}</option>
                </select>
              </div>

              <div class="grid grid-cols-2">
                <div class="grid grid-cols-1">
                  <label
                    class="mb-1 text-left uppercase md:text-sm text-xs text-gray-500 font-semibold dark:text-gray-200 font-sans"
                    >country code</label
                  >
                  <input
                    disabled
                    class="py-4 input-lg px-3 ml-2 dark:text-gray-200 mt-1 w-16 rounded-lg dark:bg-gray-700 border-2 border-pink-300 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                    type="text"
                    v-model="country.calling_codes"
                  />
                </div>
                <div class="grid grid-cols-1">
                  <label
                    class="mb-1 text-left uppercase md:text-sm text-xs text-gray-500 font-semibold dark:text-gray-200 font-sans"
                    >Phone Number</label
                  >
                  <input
                  v-model="phone"
                    class="py-4 input-lg px-3 ml-2 dark:text-gray-200 mt-1 rounded-lg dark:bg-gray-700 border-2 border-pink-300 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                    type="number"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 mt-5 mx-7"></div>
            <div class="flex md:gap-6 gap-4 pt-5 pb-5 justify-end">
              <button @click="addBasicInfo" class="btn bg-pink-400 btn-md">Save Changes</button>
            </div>
            <div class="mb-70"></div>
          </div>
        </div>
        <!--             
			<div style="margin: auto" class="
    mx-72 z-50
    grid mt-10
    lg:w-96
    xl:w-96
    md:w-2/3
    sm:w-2/3 bg-pink-100 py-3 px-2
    bg-pink-00 
    dark:bg-gray-900
    rounded-lg
    shadow-xl justify-center
">
    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="">
    <input type="file" class="file-input file-input-bordered file-input-secondary w-full max-w-xs">
</div> -->
      </div>
    </div>
    <!-- <img src="@/assets/profilesetup/Profile.jpg" style="height: 600px;" class="absolute z-negative z-0 inset-0 object-cover  w-full h-full" alt="" /> -->

    <div class="spacer mb-10">
      <footer class="z-50 bg-white w-full py-5">
        <div class="flex flex-wrap justify-center">
          <p class="font-sans font-bold uppercase mt-3">
            Submit your Information to get verified
          </p>
          <div class="grid lg:grid-cols-2 gap-2 grid-cols-2 mt-10"></div>

          <div class=" ">
            <img
              class="lg:float-right lg:h-12 h-12"
              :src="require('../assets/icons/PorntoolLogo.png')"
              alt="Connect with flirttool & start your personal online dating adventure! "
            />
          </div>
          <div>
            <img
              class="float-left lg:h-12 h-12 pl-5"
              :src="require('../assets/logo-light.png')"
              alt="Connect with flirttool & start your personal online dating adventure! "
            />
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import {BASIC_SELCETIONS,ADD_BASIC_INFO} from '@/queries/main'
import {getLocations} from '@/utils/helpers'
import helperMixin from '@/mixins/helpers'
export default {
  mixins:[helperMixin],
  data: () => ({
    bday: null,
    bmonth: null,
    byear: null,
    hairTypes: [],
    genders: [],
    gender: "",
    height: null,
    checkin: "",
    loadingSave: false,
    hair_color: "",
    phone: "",
    preference: "",
    country: "",
    allDates: [],
    counteries2:[],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  async created() {
    let setupStep = this.$store.state.core.userData.profileSetupStep
    if(setupStep == 0) {
      this.$router.push("/avatar");
    } else if(setupStep == 2) {
      this.$router.push("/verify");
    }
    this.fetchBasicInfoSelections();
    this.counteries2 = getLocations()
  },
  methods: {
    range(start, stop) {
      if (typeof stop == "undefined") {
        stop = start;
        start = 0;
      }

      const result = [...Array(stop).keys()].slice(start, stop);
      return result;
    },
    datePicked(v) {
      this.checkin = v;
      console.warn(v, "changed");
    },
    validYears() {
      let yrs = [];
      const startYear = 1940;
      const currentYear = 2004;
      for (let x = startYear; x < currentYear; x++) {
        yrs.push(x);
      }
      return yrs;
      // console.log(currentYear)
    },
    fetchBasicInfoSelections() {
      this.$apollo
        .query({
          query: BASIC_SELCETIONS,
        })
        .then(
          ({
            data: {
              basicInfoSelections: { hairColorTypes, genders, preferences },
            },
          }) => {
            this.hairTypes = hairColorTypes;
            this.genders = genders;
            this.preferences = preferences;
          }
        );
    },
    addBasicInfo() {
      const bdate = `${this.byear}-${this.months.indexOf(this.bmonth) + 1}-${
        this.bday
      }`;
      console.warn(bdate, "birth date");
      // return;
      console.log(bdate, "birth");
      const input = {
        height: this.height,
        hair_color: this.hair_color,
        phone: `${this.country.calling_codes}${this.phone}`,
        gender: this.gender,
        bdate: bdate,
        country: this.country.code,
        preference: this.preference,
      };
      if(this.country  == '') return alert('Please select country to procede')
      if(this.phone  == '') return alert('Please enter you phonenumber to procede')

      this.loadingSave = true;
      this.showSpinner()
      this.$apollo
        .mutate({
          mutation: ADD_BASIC_INFO,
          variables: {
            options: input,
          },
        })
        .then(({ data }) => {
          if (data.addBasicInformation) {
            let user = this.$store.state.core.userData;
            user.profileSetupStep = 2;
            this.$store.commit("userData", user);
            this.$router.push("/verify");
          }
          
        }).catch((e)=>{
          if(e.message){
            let message = e.message.split("GraphQL error: ")[1]
            if(message){
              alert(message)
            }
          }
        })
        .finally(() => {
          this.hideSpinner()
          this.loadingSave = false;
        });
    },
  },
}
</script>
