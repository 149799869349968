<template>
  <div class=" lg:-ml-16 lg:-mr-16 -mt-14  md:-mt-14 " >
    <!-- navi -->
    <div class="px-2 py-2 mx-auto  sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <LandingTopnav />
    </div>
    <!-- navi -->
    <!-- nav menu list -->
    <div class=" bg-gray-600">
                <ul
                    class=" flex flex-wrap py-3 justify-center  text-white font-bold font-sans text-xs uppercase lg:gap-16 gap-5">
                    <li>home</li>
                    <li> <a href="#video-category-section"> Video Catergory</a> </li>
                    <li> <a href="#hot-models"> Hot Models</a></li>
                    <li> <a href="#latest-videos"> New videos</a></li>
                    <li>Vip Models</li>
                    <li> <a href="#live-cam"> Live Cam Videos
                    </a></li>
                <li>Previews</li>
                </ul>
            </div>
    <!-- end of nav menu list -->
    <div
      class="grid   grid-cols-1 justify-center px-1 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <!-- <div class=" lg:col-span-3 flex   ">
        <div class="">
          <div class="md:flex flex-col  hidded lg:block md:flex-row md:min-h-full w-full">
            <div style="background-color: #1A1A1A;"
              class="flex flex-col w-full md:w-full text-gray-700  dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0">

              <nav
                class=" lg:block px-2  gap-8 flex-wrap grid grid-cols-2  pb-4 md:pb-0 md:overflow-y-auto shadow-xl border-0 border-gray-700">

                <div class="gap-2">

                  <a class="block px-4 py-2 mt-2 text-sm font-semibold uppercase text-red-500 bg-black rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 "
                    href="#">Caterory </a>
                  <a class="block px-4 py-1 mt-1 text-sm font-semibold text-gray-50 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-50 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    href="#">Gay <span class="text-red-500 font-sans"> {{ modelTypes.gay }}</span> </a>
                  <a class="block px-4 py-1 mt-1 text-sm font-semibold text-gray-50 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-50 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    href="#">Bisexual <span class="text-red-500 font-sans"> {{ modelTypes.bisexual }}</span>
                  </a>
                  <a class="block px-4 py-1 mt-1 text-sm font-semibold text-gray-50 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-50 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    href="#">Transgnder <span class="text-red-500 font-sans"> {{ modelTypes.trans }}</span>
                  </a>
                  <a class="block px-4 py-1 mt-1 text-sm font-semibold text-gray-50 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-50 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    href="#">Lesbain<span class="text-red-500 font-sans"> {{ modelTypes.lesbain }}</span>
                  </a>
                  <a class="block px-4 py-1 mt-1 text-sm font-semibold text-gray-50 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-50 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    href="#">Straight <span class="text-red-500 font-sans"> {{ modelTypes.straight }}</span>
                  </a>
                  <a class="block px-4 py-2 mt-2 text-sm font-semibold uppercase text-red-500 bg-black rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 "
                    href="#">vip Models </a>
                  <a v-for="model in models" :key="model.username" :href='"https://flirttool.com/ft/@" + model.username'
                    class="block px-4 py-2 mt-1 text-sm font-semibold text-gray-50 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-50 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                    <div class="flex justify-start items-center space-x-3 cursor-pointer">
                      <div class="w-5 h-5 rounded-full overflow-hidden border-2 dark:border-white border-gray-900">
                        <img :src="getUrlMedia(model.profilePic)" alt="" class="w-full h-full object-cover"
                          style="object-fit: cover;">
                      </div>
                      <div class="font-semibold dark:text-white text-gray-50 text-xs">
                        <div class="cursor-pointer font-sans">{{ model.username }}</div>
                      </div>
                    </div>
                  </a>
                  <a class="block px-4 py-2 mt-2 text-xs font-semibold text-gray-50 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-50 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    href="#">View More <span class="text-red-500 font-sans"> {{ modelTypes.total }}</span>
                    Models </a>

                  <div class="">
                    <div class="grid gap-2 sm:grid-cols- lg:grid-cols-1">
                   
                    </div>

                    <div>
                      <img src="@/assets/images/updateimages/2.jpg" />

                    </div>
                  </div>

                </div>


                <div class="">
                  <div class="grid gap-2 sm:grid-cols- lg:grid-cols-1"><a
                      class="block px-4 py-2 mt-2 text-sm font-semibold uppercase text-red-500 bg-black rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 "
                      href="#">New Video </a>
                    <div>
                      <div class="relative mb-0 sm:mx-auto md:mb-10 md:max-w-md lg:max-w-lg">
                        <MobileVideoComponents v-for="video in filteredMostViewed" :key="video.id" :video="video" />
                        <button v-if="showLoadMore" @click="loadMoreVideos"
                          class="text-white btn btn-sm mb-2 font-sans">Load More</button>
                      </div>
                    </div>
                  
                  </div>
                </div>
              
              </nav>
            </div>
          </div>
        </div>
        
      </div> -->
      <div class="lg:col-span-10">
        <!-- Main content goes here -->
        <div class="h-full ml-0 mt-0 mb-10 md:ml-0 ">
         
          <div class=" ">
            <ImgSlider />

          </div>
          <div class="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-1 p-4 gap-4 text-black dark:text-white">
            <div class="md:col-span-2 xl:col-span-3 bg-red-500">
              <h3 class="text-lg font-semibold py-2 text-left px-5 text-white italic uppercase font-sans">Recent Video</h3>
            </div>
            <div class="">
              <div class="grid gap-5 sm:grid-cols-3 grid-cols-2 lg:grid-cols-3">
              
                        <MobileVideoComponents v-for="video in filteredMostViewed" :key="video.id" :video="video" />
                       
                 

               
              </div>
            </div> 
          </div>
          <div class=" flex justify-center">
            <button v-if="showLoadMore" @click="loadMoreVideos"
                          class="text-white btn btn-sm mb-2 font-sans">Load More</button>

          </div>
          <div class="mt-3">

            <div class="grid grid-cols-1 md:grid-cols-1 mt-3 xl:grid-cols-1 p-4 gap-4 text-black dark:text-black bg-gray-50" 
                    id="hot-models">
                    <div class="md:col-span-2 xl:col-span-1 ">
                        <h3
                            class="text-2xl  flex flex-wrap gap-5 font-semibold py-5 text-left px-0 text-black  uppercase font-sans">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-9 h-9">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                            </svg>
                            <span>PornTool Models</span>
                        </h3>
                    </div>
                    <!-- new componets -->
                    <div class="px-2 py-0  sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-2 lg:py-2">
                        <div class="grid gap-2 lg:grid-cols-4 grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
                            <div class="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm"
                                v-for="model in models" :key="model.username"
                                :href='"https://flirttool.com/ft/@" + model.username'>
                                <img :src="getUrlMedia(model.profilePic)" class="object-cover w-full h-64" alt="" />
                                <div class="p-2 border border-t-0">
                                    <p class="mb-3 text-lg font-sans text-center font-semibold tracking-wide uppercase">
                                        {{ model.username }}
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- end of new component -->



                </div>
          </div>
          <div class="mt-3">

            <div class="grid grid-cols-1 mt-3 md:grid-cols-1 xl:grid-cols-1 p-4 gap-4 text-black dark:text-black bg-gray-0"
                    id="video-category-section">
                    <div class="md:col-span-2 xl:col-span-1 ">
                        <h3
                            class="text-lg  flex flex-wrap gap-5 font-semibold py-2 text-left px-0 text-black  uppercase font-sans">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="white" class="w-9 h-9">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg> <span>Watch Full Lenght Video :</span>
                        </h3>
                    </div>
                    <div class="-mt-12"> <LandingNavBar />
                    <VideoGridComponent /></div>
                   
                </div>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile -->

  </div>
</template>
<script>
import LoginComponent from '@/components/medium/LoginComponent.vue'
import { openModal } from "jenesius-vue-modal";
import { GET_ALL_MODELS_VIDEOS, GET_MODEL_TYPES_COUNT } from "@/queries/main";
import MobileVideoComponents from '@/components/small/MobileVideoComponents.vue'
import helperMixin from "@/mixins/helpers";
import LandingTopnav from '@/components/small/LandingTopnav.vue';
import ImgSlider from "@/components/small/ImgSlider.vue";
import LandingNavBar from '@/components/small/LandingNav.vue'
import VideoGridComponent from "@/components/medium/VideoGridComponent.vue";



export default {
  components: {
    MobileVideoComponents,LandingTopnav ,ImgSlider,LandingNavBar ,VideoGridComponent
  },
  mixins: [helperMixin],
  data() {
    return {
      isLargeScreen: false,
      newVideos: [],
      mostViewed: [],
      modelTypes: {
        bisexual: 0,
        gay: 0,
        lesbain: 0,
        straight: 0,
        trans: 0,
        total: 0,
      },
      models: [],
      maxVideosToShow: 7, // Set the maximum number of videos to display
      loadMoreCount: 2
    };
  },
  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
    this.checkScreenSize();
    this.getNewModelVideos();
    this.getmostViewedVideos();
    this.getModelTypesCount();
  },
  methods: {
    login() {
      const props = { title: "Hello" };
      openModal(LoginComponent, props);
    },
    logout() {
      this.logOutUser();
      this.$router.push("/");
    },
    async getModelTypesCount() {
      try {
        this.showSpinner();
        const { data: { getModelTypeCount } } = await this.$apollo.query({
          query: GET_MODEL_TYPES_COUNT,
        });
        this.hideSpinner();
        this.modelTypes.bisexual = getModelTypeCount.bisexual;
        this.modelTypes.gay = getModelTypeCount.gay;
        this.modelTypes.lesbain = getModelTypeCount.lesbain;
        this.modelTypes.straight = getModelTypeCount.straight;
        this.modelTypes.trans = getModelTypeCount.trans;
        this.modelTypes.total = getModelTypeCount.total;
        this.models = getModelTypeCount.models;
      } catch (e) {
        this.hideSpinner();
        console.log(e);
      }
    },
    async getNewModelVideos() {
      try {
        this.showSpinner();
        const { data: { getAllModelVideos } } = await this.$apollo.query({
          query: GET_ALL_MODELS_VIDEOS,
          variables: {
            page: 1,
            itemsPerPage: 50,
            isnew: true
          },
        });
        this.hideSpinner();
        this.newVideos = getAllModelVideos.media;
      } catch (e) {
        this.hideSpinner();
        console.log(e);
      }
    },
    async getmostViewedVideos() {
      try {
        this.showSpinner();
        const { data: { getAllModelVideos } } = await this.$apollo.query({
          query: GET_ALL_MODELS_VIDEOS,
          variables: {
            page: 1,
            itemsPerPage: 50,
            mostViewed: true
          },
        });
        this.hideSpinner();
        this.mostViewed = getAllModelVideos.media;
      } catch (e) {
        this.hideSpinner();
        console.log(e);
      }
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth > 1024
      if (this.isSmallScreen) {
        this.$router.push("/");
      } else {
        // You can redirect to a different page for larger screens here
      }
    },
    loadMoreVideos() {
      this.maxVideosToShow += this.loadMoreCount;
    }
  },
  computed: {
    filteredMostViewed() {
      // Apply the filter to limit the number of videos displayed
      return this.mostViewed.slice(0, this.maxVideosToShow);
    },
    visibleMostViewed() {
      return this.mostViewed.slice(0, this.maxVideosToShow + this.loadMoreCount);
    },
    showLoadMore() {
      return this.mostViewed.length > this.maxVideosToShow;
    }
  }
};

</script>

