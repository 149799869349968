
<template>
    <div class=" lg:-ml-16 lg:-mr-16  md:-mt-14 ">
        <div class="bg-black">
            <div class="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">


                <LandingTopnav />
                <!-- <div class="relative flex grid items-center grid-cols-2 lg:grid-cols-3">
                        <ul class="flex items-center  space-x-8 lg:flex">
                            
                        </ul>
                        <a href="/" aria-label="Company" title="Company" class="inline-flex items-center lg:mx-auto">

                            <img src="@/assets/images/Porntool.png" class="h-12 rounded-lg" alt="">
                        </a>
                        <ul class="flex items-center  ml-auto space-x-8 lg:flex">
                            <li><a href="/" aria-label="Sign in" title="Sign in"
                                    class="font-medium border-2  py-1 px-3 tracking-wide text-gray-100 duration-200  shadow-2xl  text-lg font-sans uppercase rounded-lg">login</a>
                            </li>
                            <li>
                                <a href="/"
                                    class="font-medium border-0  py-1 px-3 tracking-wide text-gray-100 bg-red-500 duration-200  shadow-2xl  text-lg font-sans uppercase rounded-lg"
                                    aria-label="Sign up" title="Sign up">
                                    Sign up
                                </a>
                            </li>
                       
                    </div> -->
            </div>
        </div>

        <!-- mainmenu -->

        <div class=" bg-gray-600">
            <ul
                class=" flex flex-wrap py-3 justify-center  text-white font-bold font-sans text-xs uppercase lg:gap-16 gap-5">
                <router-link to="/"><li>home</li></router-link>
                <li> <a href="#video-category-section"> Video Catergory</a> </li>
                <li> <a href="#hot-models"> Hot Models</a></li>
                <li> <a href="#latest-videos"> New videos</a></li>
                <li>Vip Models</li>
                <li> <a href="#live-cam"> Live Cam Videos
                    </a></li>
                <li>Previews</li>
            </ul>
        </div>

        <!-- end of main menu -->




        <!-- Model profile section starts here -->

        <div class="relative">
            <img src="https://flirttool.com/img/register-page-bg.63b8e7ff.jpg"
                class="absolute inset-0 object-cover w-full h-full" alt="" />
            <div class="relative bg-red-50 bg-opacity-5 bg-gradient-to-t from-black">
                <div class="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-1">
                    <div class="flex flex-col items-center justify-between mt-8 xl:flex-row">
                        <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">

                            <div class="relative pb-56 mb-36 rounded lg:justify-start justify-center flex ">
                                <img class="absolute object-cover w-56 h-56  rounded shadow-xl border-4"
                                    src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                                    alt="Person" />
                            </div>
                        </div>
                        <div class="w-full max-w-xl xl:px-8 xl:w-5/12">

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="px-4 py-0 -mt-14 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-24">
            <div class="flex flex-col items-center justify-start xl:flex-row">
                <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-3/12">

                    <div class="relative mb-36 py-4  justify-center flex flex-wrap rounded-3xl bg-gray-50 ">
                        <span class="text-xl">SEXY GIRL</span>
                    </div>

                </div>
                <div class="w-full max-w-xl justify-start lg:-mt-6 -mt-96  xl:px-8 xl:w-4/12">

                    <div class="relative mb-36 py-4  justify-center rounded-3xl flex flex-wrap  gap-10">
                        <div class="text-center font-sans font-bold text-md text-white uppercase">
                            <span>50</span><br><span>Total Videos</span>
                        </div>


                        <div class="text-center font-sans font-bold text-md text-white uppercase">
                            <span>50000+</span><br><span>Total Views</span>
                        </div>
                        <div class="text-center font-sans font-bold text-md text-white uppercase">
                            <span>1500k+</span><br><span>Subscribe </span>
                        </div>







                    </div>

                </div>
                <div class="w-full max-w-xl justify-start -mt-5  xl:px-8 xl:w-5/12">

                    <div class="relative mb-36 py-4  justify-center rounded-3xl flex flex-wrap  gap-10">
                        <div><button
                                class=" py-1 px-4 bg-red-500 text-lg shadow-xl font-sans uppercase text-white  rounded-full">Subcribe</button>
                        </div>


                        <div><button
                                class=" py-1 px-4 bg-pink-500 text-lg shadow-xl font-sans uppercase text-white  rounded-full">Become
                                a fan</button></div>








                    </div>

                </div>
            </div>


        </div>
        <!-- About Section -->
        <div
            class="px-4 py-4 -mt-48  bg-gray-50 mb-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-24 ">
            <span class="block w-full text-red-500 text-2xl uppercase text-center mt-5  ">
                About</span>
            <div class="text-gray-700">
                <div class="grid md:grid-cols-2 text-sm">
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">RelationShip Status</div>
                        <div class="px-2 py-2">Jane</div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">Country</div>
                        <div class="px-2 py-2">LK</div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">Gender</div>
                        <div class="px-2 py-2">Female</div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">Interested In</div>
                        <div class="px-2 py-2">Girls</div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">Hair Colour</div>
                        <div class="px-2 py-2">Beech Creek</div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">Weight</div>
                        <div class="px-2 py-2">Arlington Heights, IL, Illinois</div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">Email.</div>
                        <div class="px-2 py-2">
                            <a class="text-blue-800" href="mailto:jane@example.com">jane@example.com</a>
                        </div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="px-2 py-2 font-bold font-sans uppercase">Birthday</div>
                        <div class="px-2 py-2">Feb 06, 1998</div>
                    </div>
                </div>
            </div>



            <div class="bg-gray-50">
                <span class="block w-full text-red-500 text-2xl uppercase text-center mt-5  ">
                    Gallery</span>
                <ModelGallery />
            </div>


        </div>
        <!-- End of about section -->
        <!-- model profile section ends here -->
        <div>
            <ModelVideo />
        </div>



       



        <div
            class="px-4 py-4  bg-gray-50 mb-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-24">
            <div class="grid grid-cols-1 mt-3 md:grid-cols-1 xl:grid-cols-1 p-4 gap-4 text-black dark:text-black bg-gray-0"
                id="video-category-section">
                <div class="md:col-span-2 xl:col-span-1 ">
                    <h3
                        class="text-2xl  flex flex-wrap gap-5 font-semibold py-2 text-left px-0 text-black  uppercase font-sans">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="white" class="w-9 h-9">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg> <span>Watch Full Lenght Video :</span>
                    </h3>
                </div>
                <LandingNavBar />
                <VideoGridComponent />
            </div>
        </div>


    </div>
</template>

<script>
import LandingTopnav from '@/components/small/LandingTopnav.vue';
import ModelGallery from '@/components/small/ModelGallery.vue'
import ModelVideo from '@/components/small/ModelVideo.vue'
import VideoGridComponent from '@/components/medium/VideoGridComponent.vue';
import LandingNavBar from '@/components/small/LandingNav.vue';
// import LandingVideoComponent from '@/components/small/LandingVideoComponent.vue';

export default
    {
        components: {
            LandingTopnav, ModelGallery, ModelVideo, VideoGridComponent, LandingNavBar
        },

    };



</script>