<template>
<nav aria-label="Posts navigation" class="col-12">
    <ul class="pagination pagination-lg justify-content-center">

      <li v-if="showLeftArrow" class="page-item">
        <a @click.prevent="changePage(currentPage- 1)" class="prev page-link" href="#">&laquo;</a>
      </li>
      <li v-if="showFirstPage" class="page-item">
        <a @click.prevent="changePage(1)" class="page-link" href="#">1</a>
      </li>
        <li v-if="showLeftDots" class="page-item">
        <span class="page-link dots">&hellip;</span>
      </li>
      <li v-for="link in links" :key="link" class="page-item"
      :class="{'active':link == currentPage}"
      >
        <a @click.prevent="changePage(link)" class="page-link" href="#">{{link}}</a>
      </li>
      <li v-if="showRightDots" class="page-item">
        <span class="page-link dots">&hellip;</span>
      </li>
      <li v-if="showLastPage" class="page-item">
        <a @click.prevent="changePage(mutablePages)" class="page-link" href="#">{{mutablePages}}</a>
      </li>

      <li v-if="showRightArrow" class="page-item">
        <a @click.prevent="changePage(currentPage + 1)" class="next page-link" href="#">&raquo;</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default  {
  props:['pages','currentPage'],
  data() {
    return {
      maxLinks:5,
      showLeftDots:false,
      showLeftArrow:false,
      showRightDots:false,
      showRightArrow:false,
      links:[],
      showLastPage:true,
      showFirstPage:false,
      mutableCurrentPage:[],
      mutablePages:0
    }
  },
  mounted() {
    this.mutableCurrentPage = this.currentPage
    this.mutablePages = this.pages
    this.paginationLinks()
  },
  methods: {
    changePage(page) {
      this.$emit('changePage',page)
    },
    paginationLinks() {
      let links = []
      if(this.maxLinks > this.mutablePages) {
        for(let l=1;l<=this.mutablePages;l++) {
          links.push(l)
        }
        this.showLastPage = false
        this.showFirstPage = false
        this.showLeftArrow = false
        this.showRightDots = false
        this.showRightArrow = false
        this.showLeftDots = false
        this.links = links
      }else if(this.mutableCurrentPage <= this.maxLinks) {
        this.showLastPage = true
        this.showFirstPage = false
        for(let l=1;l<=this.maxLinks;l++) {
          links.push(l)
        }
        if(this.mutableCurrentPage > 1) {
          this.showLeftArrow = true
        }else {
          this.showLeftArrow = false
          this.showRightDots = true
        this.showRightArrow = true
        this.showLeftDots = false

        }
        
      }else if(this.mutableCurrentPage > this.maxLinks && this.mutableCurrentPage != this.mutablePages ){
        console.log('in 3')
        this.showFirstPage = true
        this.showLastPage = true
        let startingPage = this.mutableCurrentPage - Math.ceil(this.maxLinks/2) 
        console.log(startingPage)
         for(let l=startingPage;l<=startingPage+this.maxLinks;l++) {
           if(l <= this.mutablePages) {
            links.push(l)
          }
        }
        this.showLeftDots = true
        this.showLeftArrow = true
        this.showRightDots = true
        this.showRightArrow = true

      }else {
        this.showFirstPage = true
        this.showLastPage = false
        let startingPage = this.mutableCurrentPage - Math.ceil(this.maxLinks/2) 
        console.log(startingPage)
         for(let l=startingPage;l<=startingPage+this.maxLinks;l++) {
           if(l <= this.mutablePages) {
            links.push(l)
          }
        }
        this.showLeftDots = true
        this.showLeftArrow = true
        this.showRightDots = false
        this.showRightArrow = false
      }
      this.links = links
    }
  },
  watch: { 
    currentPage: function (value) {
      this.mutableCurrentPage = value
      this.paginationLinks()
    },
    pages: function (value) {
      this.mutablePages = value
      this.paginationLinks()
    },
  },
  // computed: {
  //   paginationLinks:function() {
  //     let links = []
  //     for(let l=this.currentPage;l<this.maxLinks;l++) {
  //       links.push(l)
  //     }
  //   }
  // }
  
}
</script>










