import env from '../env'
import {GET_USER_PORNTOOL_SUBSCRIPTIONS} from '@/queries/main'
const helperMixin = {
  data() {
    return {
      baseUrl:env.production ? 'https://porntool.live/' : "http://localhost:4000/",
      baseUrlFT:env.production ? 'https://flirttool.com/' : "http://localhost:4000/",
      baseUrlMedia:env.production ? 'https://flirttool.com/media/' : "http://localhost:4000/media/",
      peerUrl:env.production ? 'cam.porntool.live' : "localhost",
    }
  },
  methods: {
    async setUserSubscriptions() {
      if(!this.$store.state.core.loggedIn) return 
      const {data :{getUserPorntoolSubscriptions}} = await this.$apollo.query({
        query: GET_USER_PORNTOOL_SUBSCRIPTIONS,
      })
      this.$store.commit("porntoolSubscriptions",getUserPorntoolSubscriptions)
      let hasActiveSubscription = getUserPorntoolSubscriptions.find(s => s.expired == false)
      if(hasActiveSubscription) {
        this.$store.commit("hasActiveSubscription",true)
      }else{
        this.$store.commit("hasActiveSubscription",false)
      }
    },
    getUrl(path){
      return `${this.baseUrl}${path}`
    },
    getUrlFT(path){
      return `${this.baseUrlFT}${path}`
    },
    getUrlMedia(path){
      return `${this.baseUrlMedia}${path}`
    },
    showSpinner() {
      this.$store.commit("loading",true)
    },
    hideSpinner() {
      this.$store.commit("loading",false)
    },
    async refreshUserData() {
      let { data } = await this.axios.post('user/data',{
        loadingMesssage:"Please wait.."
      })
      this.logOutUser()
      this.$store.commit("userData",data.user)
      this.$store.commit("userAccounts",data.accounts)
      this.$store.commit("activeAccount",data.activeAccount)
      this.$store.commit("token",data.token)
      this.$store.commit("adminloggedIn",false)
      this.$store.commit("loggedIn",true)

    },
    logOutUser() {
      this.$store.commit("userData",{})
      this.$store.commit("loggedIn",false)
      this.$store.commit("token",null)
      this.$store.commit("tags",[])
      this.$store.commit("hasActiveSubscription",false)
      this.$store.commit("porntoolSubscriptions",[])
    }
  }
}

export default helperMixin