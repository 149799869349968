<template>
    <!-- ******************* The Navbar Area ******************* -->
        <div id="wrapper-navbar" itemscope itemtype="http://schema.org/WebSite">
          <a class="skip-link sr-only sr-only-focusable" href="#content">Skip to content</a>
          <div class="header-nav">
            <div class="container d-md-flex align-items-center justify-content-between">
              <div class="logo-search d-flex align-items-center">
                <!-- Menu mobile -->
                <button @click="toggleTagBar" class="navbar-toggler hamburger hamburger--slider  d-block" :class="{'is-active':showTagBar,'collapsed':!showTagBar }" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="hamburger-box">
                <span class="hamburger-inner"></span>
                </span>
                </button>
                <!-- Menu desktop -->
                <div class="logo">
                  <!-- Your site title as branding in the menu -->
                  <h1>
                    <router-link  to="/" class="logo-text"  title="PornTool" itemprop="url">
                    <span class="logo-word-1">Porn </span>
                    <span class="logo-word-2">Tool</span>
                    </router-link>
                  </h1>
                  <!-- end custom logo -->
                </div>
                <div class="d-none d-md-block header-search">
                  <form method="get" id="searchform" action="https://demo-vtt-adult.wp-script.com/">        
                    <input class="input-group-field" value="Search..." name="s" id="s" onfocus="if (this.value == 'Search...') {this.value = '';}" onblur="if (this.value == '') {this.value = 'Search...';}" type="text" />
                    <button class="fa-input" type="submit" id="searchsubmit" value="&#xf002;" ><i class="fa fa-search"></i></button>     
                  </form>
                </div>
                <div class="d-block d-md-none membership">
                  <div class="d-none d-md-inline-block">
                    <span class="login"><a class="btn btn-primary" href="#wpst-register">Sign Up</a></span>
                    <span @click.prevent="login" class="login"><a class="login-link" href="#wpst-login">Login</a></span>
                  </div>
                  <div class="d-inline-block d-md-none user-mobile">
                    <a href="#wpst-login"><i class="fa fa-user"></i></a>
                  </div>
                </div>
              </div>
              <h2 class="d-none d-xl-block header-title">
                    <span class="logo-word-2 text-white">Flirttool</span>
                    <span class="logo-word-1"> VIP </span>
                    <span class="logo-word-2 text-white ">Models</span>
              </h2>
              <div v-if="!$store.state.core.loggedIn" class="d-none d-md-block membership">
                <span class="login"><router-link  class="btn btn-primary btn-sm font-sans" to="/register">Sign Up</router-link></span>
                <span @click.prevent="login" class="login"><a class="login-link font-sans" href="#wpst-login">Login</a></span>
              </div>
              <div v-if="$store.state.core.loggedIn" class="d-none d-md-block membership">
                <span @click.prevent="logout" class="login"><a class="btn btn-primary" href="#wpst-register">Logout</a></span>
                <span class="login"><router-link to="/membership" class="btn btn-primary" href="#wpst-register">Membership</router-link></span>
                <span class="login">
                  <img class="image-profile" :src="getUrlMedia($store.state.core.userData.profilePic)">
                </span>
  
              </div>
            </div>
          </div>
          <!-- <h2 class="d-block d-md-none header-title">XVideos Clone WP Template</h2> -->
          <div class="d-block d-md-none header-search">
            <form method="get" id="searchform" action="https://porntool.live/">        
              <input class="input-group-field" value="Search..." name="s" id="s" onfocus="if (this.value == 'Search...') {this.value = '';}" onblur="if (this.value == '') {this.value = 'Search...';}" type="text" />
              <button class="fa-input" type="submit" id="searchsubmit" value="&#xf002;" ><i class="fa fa-search"></i></button>     
            </form>
          </div>
          <!-- <nav class="navbar navbar-expand-md navbar-dark">
            <div class="container">
            
              <Transition>
              <div  v-if="showTagBar && !smallWidth" class=" navbar-collapse show">
                <ul id="menu-main-menu" class="navbar-nav ml-auto">
                  <li v-for="tag in tags" :key="tag.id" class="menu-item nav-item tag-menu">
                    <a @click.prevent="toggleTag(tag)" href="#" class="btn btn-xs text-sm font-sans"
                      :class="{'btn-primary':$store.state.core.tags.includes(tag.tag_name),'btn-grey':!$store.state.core.tags.includes(tag.tag_name)}"
                    >{{tag.tag_name}}</a>
                  </li>
                </ul>
              </div>
              </Transition>
            </div>
           
          </nav> -->
          <!-- .site-navigation -->
          <Transition>
          <ul v-if="smallWidth && showTagBar " class="mobile-tag-nav ml-auto">
            <li v-for="tag in tags" :key="tag.id" class="menu-item nav-item tag-menu">
              <a @click.prevent="toggleTag(tag)" href="#" class="btn btn-sm"
                :class="{'btn-primary':$store.state.core.tags.includes(tag.tag_name),'btn-grey':!$store.state.core.tags.includes(tag.tag_name)}"
              >{{tag.tag_name}}</a>
            </li>
          </ul>
          </Transition>
  
          <div class="clear"></div>
        </div>
        
  </template>
  
  <script>
  /* eslint-disable */
  import { GET_ALL_TAGS } from '@/queries/main'
  import LoginComponent from '@/components/medium/LoginComponent.vue'
  import {openModal} from "jenesius-vue-modal";
  import helperMixin from '@/mixins/helpers';
  export default {
    mixins:[helperMixin],
     apollo: {
      getAllTags: {
        query: GET_ALL_TAGS,
        result({data :{getAllTags}}) {
           this.tags = getAllTags
        },
      },
    },
    mounted() {
      const mq = window.matchMedia( "(min-width: 750px)" );
      if(!mq.matches) {
        this.showTagBar = false
        this.smallWidth = true
      }
    },
    data() {
      return {
        tags: [],
        showTagBar:true,
        smallWidth:false
      }
    },
    methods: {
      logout() {
        this.logOutUser()
        this.$router.push("/")
      },
      login() {
        const props = {title: "Hello"};
        openModal(LoginComponent, props)
      },
      toggleTagBar() {
        this.showTagBar = !this.showTagBar
      },
      toggleTag(tag) {
        let tags = this.$store.state.core.tags
        let tagName = tag.tag_name
        const findIndex = tags.indexOf(tagName);
        if(findIndex > -1) {
          tags.splice(findIndex, 1);
          this.$store.commit("tags",tags)
        }else {
          tags.push(tagName)
          this.$store.commit("tags",tags)
        }
      }
    }
  }
  </script>
  <style>
  .image-profile {
        height: 37px;
      width: 43px;
     
      display: inline-block;
      border-radius: 100%;
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .mobile-tag-nav  {
    padding:15px;
  }
  .mobile-tag-nav li {
      float: left;
      font-size: 14px;
      margin: 0 5px 0 0;
      margin-top:4px;
     list-style-type: none;
      white-space: nowrap;
  }
  </style>